import * as CONSTS from "./const"
import { LocalStorageService } from "./local-storage"
import { ApiService } from "./api/api"
export class AuthService {
    apiService: ApiService
    localStorageService: LocalStorageService

    constructor(
        localStorageService: LocalStorageService,
        apiService: ApiService
    ) {
        this.localStorageService = localStorageService
        this.apiService = apiService
    }

    async authenticate(user: string, password: string) {
        const errorAuthenticateMessage = "Não foi possivel autenticar no sistema"
        try {
            const userToken = await this.apiService.login(user, password)

            if (!userToken) {
                throw new Error(errorAuthenticateMessage)
            }

            this.localStorageService.setItem(CONSTS.AUTHORIZED_LOCALSTORAGE_ITEM, "true")
            this.localStorageService.setItem(CONSTS.AUTHORIZED_USER_LOCALSTORAGE_TOKEN, userToken.data.token)

            return CONSTS.API_VALID_USER_MESSAGE

        } catch (error) {
            if (
                (error as any)?.response?.status === 422 ||
                (error as any)?.response?.status === 401
            ) {
                throw errorAuthenticateMessage
            }
            throw error;
        }
    }

    async whoIam(): Promise<any> {
        try {
            const whoIam = await this.apiService.whoIam();

            return whoIam.data
        } catch (error) {
            throw new Error('Problema ao executar whoIam')
        }
    }

    isAlreadyLogged = () => this.localStorageService.getItem(CONSTS.AUTHORIZED_LOCALSTORAGE_ITEM)
}