import { ApiService } from './api/api'
import { AuthService } from './auth'
import { AuthorizedPersonApiService } from './api/authorized-person-api'
import { CondominiumApiService } from './api/condominium-api'
import { DeliveryApiService } from './api/delivery-api'
import { DoorApiService } from './api/door-api'
import { AxiosHttpService, HttpService } from './api/http'
import { LocalStorageService } from './local-storage'
import { PeopleApiService } from './api/people-api'
import { ScheduleApiService } from './api/scheduled-api'
import { TicketApiService } from './api/tickets-api'
import { CondominiumGateKeeperApiService } from './api/condominium-gatekeeper-api'

type Servicefacade = {
    apiService: ApiService,
    authService: AuthService,
    localStorageService: LocalStorageService,
    ticketApiService: TicketApiService,
    condominiumApiService: CondominiumApiService
    doorApiService: DoorApiService,
    peopleApiService: PeopleApiService,
    authorizedPersonApiService: AuthorizedPersonApiService,
    scheduleApiService: ScheduleApiService,
    deliveryApiService: DeliveryApiService,
    condominiumGateKeeperApiService: CondominiumGateKeeperApiService
}

export class ContainerService {
    static facade: Servicefacade
    constructor() { }

    build(): Servicefacade {
        if (!ContainerService.facade) {
            const localStorageService = new LocalStorageService()
            const httpService: HttpService = new AxiosHttpService(localStorageService)
            const apiService = new ApiService(localStorageService, httpService)
            const condominiumApiService = new CondominiumApiService(localStorageService, httpService)
            const doorApiService = new DoorApiService(localStorageService, httpService)
            const peopleApiService = new PeopleApiService(localStorageService, httpService)
            const authorizedPersonApiService = new AuthorizedPersonApiService(localStorageService, httpService)
            const scheduleApiService = new ScheduleApiService(localStorageService, httpService)
            const deliveryApiService = new DeliveryApiService(localStorageService, httpService)
            const authService = new AuthService(localStorageService, apiService)
            const ticketApiService = new TicketApiService(httpService)
            const condominiumGateKeeperApiService = new CondominiumGateKeeperApiService(localStorageService, httpService)
            const facade = {
                apiService,
                authService,
                localStorageService,
                ticketApiService,
                condominiumApiService,
                doorApiService,
                peopleApiService,
                authorizedPersonApiService,
                scheduleApiService,
                deliveryApiService,
                condominiumGateKeeperApiService
            }

            ContainerService.facade = facade
        }

        return ContainerService.facade
    }
}