import React from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const conditionalRowStyles = [
    {
        when: (row: any) => true,
        style: {
            '&:hover': {
                backgroundColor: '#dcd1d1de',
                cursor: 'pointer',
            },
        },
    },
];

const customStyles = {
	headRow: {
		style: {
			border: 'none',
		},
	},
	headCells: {
		style: {
			color: '#202124',
			fontSize: '14px',
		},
	},
	rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 244, 244)',
			borderBottomColor: '#FFFFFF',
			borderRadius: '25px',
			outline: '1px solid #FFFFFF',
		},
	},
	pagination: {
		style: {
			border: 'none',
		},
	},
};

export const FORMAT_DATE: string = "DD/MM/YYYY"
export const FORMAT_DATE_TIMESTAMP: string = "DD/MM/YYYY HH:mm"
export const FormatDate = (date: string, format = FORMAT_DATE_TIMESTAMP) => {
    return moment(date).format(format)
}

const DataTableContainer: React.FC<any> = (params: any) => {
    //deixar params editavel
    return (<>
        <DataTable
            columns={params.columns}
            data={params.data}
            pagination={true}
            paginationServer={true}
            onRowClicked={params.onRowClicked}
            noDataComponent={'Não há registros para serem mostrados.'}
            paginationPerPage={params.paginationPerPage}
            onChangePage={params.onChangePage}
            progressPending={params.progressPending}
            striped={true}
            noHeader={true}
            onChangeRowsPerPage={params.onChangePage}
            paginationTotalRows={params.paginationTotalRows}
            paginationRowsPerPageOptions={[params.paginationPerPage]}
            paginationComponentOptions={{
                rowsPerPageText: 'Registros por pagina:',
                rangeSeparatorText: 'de',
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: 'Todos'
            }}
            conditionalRowStyles={conditionalRowStyles} />

    </>);
};

export default DataTableContainer;