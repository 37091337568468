import React, {useEffect} from 'react';
import './DeliveryForm.css';
import RoutesConsts from "../../consts/RoutesConsts";
import FormContainer from "../../components/FormContainer/FormContainer";
import {
  TYPE_AUTO_COMPLETE,
  TYPE_FILEUPLOAD,
  TYPE_ID,
  TYPE_SELECT,
  TYPE_TEXT,
} from "../../components/FormContainer/Field"

import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Page from '../../components/Page/Page';
import _ from 'lodash';
import { getDelivery, upsertDelivery } from '../../actions';
import { cleanFieldAutocompleteSearch, createFormField } from '../../actions/form';
import { useHistory } from 'react-router';
import { ContainerService } from '../../services/container';
import { cleanForm, formHasPersisted, formHasSubmitted, getAutocompleteFieldValue, getFormUseEffectArray, submitForm, updatedAutocompleteOptions } from '../../components/FormContainer/FormEffects';

const DeliveryForm: React.FC = (params: any) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const formStore = useSelector((state: any) => state.formState)

  const id: number = parseInt(params.match.params.id)
  const name = 'delivery'
  const backToRoute = RoutesConsts.DELIVERY
  const title = 'Entregas'

  const containerService = new ContainerService().build()

  const isEdit = () => {
    return id && id !== 0
  }

  //colocar em actions/index.tsx
  const loadForm = (id: number) => {
    const fields = {
      "id": { type: TYPE_ID, name: "id", row: 1, group: "delivery", label: "Cod. Pessoa Authorizada", offset: 0, value: 0, validation: null },
      "name": { type: TYPE_TEXT, name: "name", row: 1, group: "delivery", label: "Pacote", value: "", validation: Yup.string().required('Informe o pacote') },
      "peopleId": { type: TYPE_AUTO_COMPLETE, name: "peopleId", row: 2, offset: 0, group: "delivery", options: [], async: true, multiple: false, label: "Destinatario", value: null, validation: Yup.object().nullable().required('Selecione alguma pessoa') },
      "condominiumId": { type: TYPE_AUTO_COMPLETE, name: "condominiumId", row: 2, offset: 0, group: "delivery", options: [], async: true, multiple: false, label: "Condominio", value: null, validation: Yup.object().nullable().required('Selecione algum condomium') },
      "type": { type: TYPE_SELECT, name: "type", row: 3, group: "delivery", label: "Tipo", options: [{ value: "CORREIOS", label: "Correios" }, { value: "MOTOBOY", label: "Motoboy" }], value: "", validation: null },
      "identification": { type: TYPE_TEXT, name: "identification", row: 3, group: "delivery", label: "Identificação", value: "", validation: null },
      "files": { type: TYPE_FILEUPLOAD, name: "files", row: 4, offset: 1, group: "delivery", label: "Arquivos", value: [], validation: null },
    }

    //carrega form no redux - se nao colocar nao será renderizado pelo componente
    dispatch(createFormField(name, fields))

    if (isEdit()) {
      //altera campos de acordo com a edição
      console.log("Carregando dados do id", id)
      dispatch(getDelivery(id, fields))
    }

  }

  const fetchAutocompletePeople = async (peopleValue: any) => {
    const filter = {
      filter: {
        where: {
          name: { like: `${peopleValue}%` }
        }
      }
    }

    const people: any = await containerService.peopleApiService.getPeoples(filter)
    if (people.data) {
      return people.data.reduce((accPeople: any, people: any) =>
        [...accPeople, { id: people.id, label: `${people.id} - ${people.name}` }], [])
    }
    return []
  }

  const fetchAutocompleteCondominium = async (condominiumValue: any) => {
    const filter = {
      filter: {
        where: {
          name: { like: `${condominiumValue}%` }
        }
      }
    }

    const condominiums = await containerService.condominiumApiService.getCondominiums(filter)
    if (condominiums.data) {
      return condominiums.data.reduce((accCondominium: any, condominium: any) =>
        [...accCondominium, { id: condominium.id, label: `${condominium.id} - ${condominium.name}` }], [])
    }
    return []
  }

  //init
  useEffect(() => {
    //Carrega dados do formulario para redux
    //para cada form é necessario um dispatch para criar os dados no redux
    loadForm(id)

    return () => {
      //unmount
      dispatch(cleanFieldAutocompleteSearch(name))
    }
  }, [])

  //atualização de form
  useEffect(() => {
    //verificar se form data mudou
    if (formHasSubmitted(formStore, name)) {
      //envia para saga de criação de pessoas (cria usuario se for novo)
      submitForm(dispatch, formStore, name, upsertDelivery)
    }

    //se o upsert mudar o formulario pra persister quer dizer que inseriu o dado ok
    if (formHasPersisted(formStore, name)) {
      //limpa estado do form - tentar colocar no unmount
      cleanForm(dispatch, name)
      history.push(backToRoute)
    }

    if (getAutocompleteFieldValue(formStore, name, "peopleId")) {
      //atualiza os campos do autocomplete de condominiumId
      updatedAutocompleteOptions(dispatch, formStore, name, "peopleId", fetchAutocompletePeople)
    }

    if (getAutocompleteFieldValue(formStore, name, "condominiumId")) {
      //atualiza os campos do autocomplete de condominiumId
      updatedAutocompleteOptions(dispatch, formStore, name, "condominiumId", fetchAutocompleteCondominium)
    }

    //ADICIONA O PERSIST E O SUBMITED E CAMPOS ADICIONAIS PARA AUTOCOMPLETE
  }, getFormUseEffectArray(formStore, name, [getAutocompleteFieldValue(formStore, name, "peopleId"), getAutocompleteFieldValue(formStore, name, "condominiumId")]))

  return (
    <Page title={title} backToRoute={backToRoute}>
      <FormContainer name={name} />
    </Page>
  )
};

export default DeliveryForm;
