import * as CONSTS from "../const"
import { HttpService } from './http'

export class TicketApiService {

    constructor(
        private httpService: HttpService
    ) { }

    async getTopics(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM_MESSAGE}`
        const headers = { ...this.httpService.getHeader() }
        const requestQuery: any = {
            headers,
            params: {
                ...params,
                filter: {
                    include: [{ relation: "condominium" }, { relation: "people" }],
                    where: { ...params.filter.where, message_id: null }
                },
            },
        }

        const response = await this.httpService.get(url, requestQuery)

        return response
    }

    async getTopicsCount(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM_MESSAGE_COUNT}`
        const headers = { ...this.httpService.getHeader() }
        const requestQuery: any = {
            headers,
            params: {
                ...params,
                filter: {
                    include: [
                        { relation: "condominium" },
                        { relation: "people" }
                    ]
                },
                where: {
                    ...params.where,
                    message_id: { eq: null }
                }
            },
        }

        const response = await this.httpService.get(url, requestQuery)

        return response
    }

    async deleteTicket(id: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM_MESSAGE}/${id}`
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.delete(url, headers)

        return response
    }
}