import * as CONSTS from "../const"
import { ApiService } from "./api"

export class CondominiumGateKeeperApiService extends ApiService {

    async createCondominiumGateKeeper(condominiumGateKeeper: any) {

        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM_GATEKEEPER}`
            const headers = { ...this.httpService.getHeader() }
            const condominiumGateKeeperResponse: any = await this.httpService.post(url, condominiumGateKeeper, headers)

            if (!condominiumGateKeeperResponse.data) {
                throw new Error("Error ao criar porteiro")
            }

            return condominiumGateKeeperResponse.data
        } catch (error) {
            return error
        }
    }

    async updateCondominiumGateKeeper(condominiumGateKeeper: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM_GATEKEEPER}/${condominiumGateKeeper.id}`
            const headers = { ...this.httpService.getHeader() }
            const condominiumGateKeeperResponse: any = await this.httpService.put(url, condominiumGateKeeper, headers)

            if (!condominiumGateKeeperResponse.data) {
                throw new Error("Error ao atualizar porteiro")
            }

            return condominiumGateKeeperResponse.data
        } catch (error) {
            return error
        }
    }

    async getCondominiumGateKeeper(id: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM_GATEKEEPER}/${id}`
            const params: any = { filter: { include: [{ relation: 'condominium' }, { relation: 'condominiumDoor' }] } }
            const headers = { ...this.httpService.getHeader(), params }
            const condominiumGateKeeperResponse: any = await this.httpService.get(url, headers)

            if (!condominiumGateKeeperResponse.data) {
                throw new Error("Error ao carregar porteiro")
            }

            return condominiumGateKeeperResponse.data
        } catch (error) {
            return error
        }
    }

    async getCondominiumGateKeepers(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM_GATEKEEPER}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async getCondominiumGateKeeperCount(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM_GATEKEEPER_COUNT}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async deleteCondominiumGateKeeper(id: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM_GATEKEEPER}/${id}`
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.delete(url, headers)

        return response
    }

}
