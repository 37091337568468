import React, { useEffect, useState } from 'react';
import { IonAlert, IonButton, IonCol, IonGrid, IonIcon, IonRow, IonInput } from '@ionic/react';
import { Button, TextField } from '@material-ui/core';
import { addCircleOutline, createOutline, refreshOutline, searchOutline, trashOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import DataTableContainer from "../DataTable/DataTableContainer";
import Loader from '../Loader/Loader';
import moment from 'moment';
import './ApiDataTable.css';

/*params:
    route_create -> RoutesConsts.PEOPLE_FORM
    filter -> filter loopback
    countService -> count loopback
    getService -> get loopback
    columns -> columns do loopback
*/

const DEFAULT_GRID_LIMIT = 50;
const DEFAULT_GRID_OFFSET = 0;
const DEFAULT_GRID_ORDER = "id DESC";

const ApiDataTable: React.FC<any> = (params: any) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const createRoute = `${params.routeCreate}/0`
  const initDataTableFilter =
  {
    filter: {
      offset: 0,
      limit: 50,
      where: {
        deletedAt: {
          eq: null
        }
      },
      include: params.include,
      order: "id DESC"
    }
  }

  const [data, setData] = useState([] as any)
  const [filter, setFilter] = useState(params.filter || initDataTableFilter as any)
  const [count, setCount] = useState(0 as number)
  const [loadingDatatable, setLoadingDatatable] = useState(false as any)

  const initialDateRange = { start: moment().subtract(7, "days").toDate(), end: moment().toDate() }
  const [dateRange, setDateRange] = useState(initialDateRange as any)
  const [search, setSearch] = useState("" as any)

  const [columns, setColumns] = useState(params.columns as any);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false as boolean);
  const [selectedIdRow, setSelectedIdRow] = useState(0 as any);
  const [notRendered, setNotRendered] = useState(false)

  const getData = async (filter: any) => {
    setLoadingDatatable(true)

    try {
      dispatch({ type: 'PERSISTING_API' })

      const { where } = filter.filter
      const count = await params.countService({ where })

      setCount(count.data.count)

      filter.search = {
        value: search
      };

      const data = await params.getService(filter)

      setData(data.data)
    } catch (error) {
      noResultsError()
    } finally {
      dispatch({ type: 'STAND_BY' })
    }

    setLoadingDatatable(false)
  }

  const calculateOffset = (page: number, limit: number) => (page * limit) - limit

  const changePage = (page: number, total?: any) => {
    const isPageOne = page === 1
    const newOffset = !isPageOne ? calculateOffset(page, filter.filter.limit) : 0
    const newFilter = 
      { 
        filter:{
          ...filter.filter,
          offset: newOffset
        }, 
        order: DEFAULT_GRID_ORDER
      }
                      
    return newFilter
  }

  const mountWhereOrFields = (params:any, onlyRelations:boolean = false) => {
    let byPass = ["createdAt", "updatedAt", "optionsRows"]
    
    const whereOrFields = 
      params
      .columns
      .reduce((whereOrFieldsAccumulator: any, column: any) => {
        const selectorNotByPassed = !byPass.find(b => column.selector == b)
        
        if (selectorNotByPassed) {
          const isRelationField = (column:any) => column.selector.includes('.')
          
          if(isRelationField(column) && onlyRelations || !onlyRelations){
            let selectorName = column.selector
            if(isRelationField(column)){
              [, selectorName] = column.selector.split('.')
            }

            whereOrFieldsAccumulator = 
            [
              {
                [selectorName]: {like: `%${search}%`}
              },
              ...whereOrFieldsAccumulator
            ]
          }
        }

        return whereOrFieldsAccumulator
      }, [])

    const deleteAt = {
      deletedAt: null
    }

    return search ?
      {
        and: [
          deleteAt,
          {
            or: whereOrFields
          }
        ]
      } : deleteAt
  }
        
  const searchButton = async () => { 
    const whereFilter = mountWhereOrFields(params)
    let includeFilter = []    

    if(params.include){
      const whereIncludeFilter = mountWhereOrFields(params, true)
      
      includeFilter =
        params.include
        .reduce((includeFilterAccumulator:any, include:any) => {
          include = {
            scope:{
              where: whereIncludeFilter
            },
            ...include
          }
          return [include, ...includeFilterAccumulator]
        }, [])
    }

    const newFilter = { 
      filter:{
        limit: filter.limit || DEFAULT_GRID_LIMIT,
        offset: filter.offset || DEFAULT_GRID_OFFSET,
        where: whereFilter,
        include: includeFilter,
        order: DEFAULT_GRID_ORDER
      },
      search: {
        value: search
      }
    }

    setFilter(newFilter)
    await getData(newFilter)
  }

  const resetForm = async () => {
    setDateRange(initialDateRange)
    setSearch("")
    setFilter(initDataTableFilter)
    await getData(initDataTableFilter)
  }

  const createNew = () => {
    history.push(createRoute)
  }

  const edit = (id: number) => {
    const editAccessRoute = `${params.routeCreate}/${id}`
    history.push(editAccessRoute)
  }

  const noResultsError = () => {
    setCount(0)
    setData([])
  }

  const addOptionsButtonToDataTable = (editAccessRoute: string) => {
    const optionColumnField = {
      name: 'Opções',
      selector: 'optionsRows',
      right: true,
      cell: (row: any) => {
        return <>
          <IonButton color="primary" size="small" onClick={e => history.push(`${params.routeCreate}/${row.id}`)}>
            <IonIcon icon={createOutline} />
          </IonButton>
          <IonButton color="danger" size="small" onClick={e => { setSelectedIdRow(row.id); setShowDeleteAlert(true) }}>
            <IonIcon icon={trashOutline} />
          </IonButton>
        </>
      }
    }

    const newColumnsWithOpt = [...columns, optionColumnField]

    setColumns(newColumnsWithOpt)
  }

  useEffect(() => {
    setTimeout(() => {
      setNotRendered(true)
      addOptionsButtonToDataTable(params.routeCreate)
      getData(filter)
    }, 1000)
  }, []);

  return (<>
    {notRendered ? <>
      <IonGrid>
        <IonRow>
          <IonCol class="ion-flex ion-justify-content-end">
            <IonButton color="warning" onClick={createNew}>
              <IonIcon icon={addCircleOutline} />&nbsp;{params.textNew}
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="6" className="ion-flex ion-align-items-center">
            <IonInput
              style={{width: '150px'}}
              className="table-filter-input"
              placeholder="Procurar"
              value={search}
              onIonChange={async (e) => {
                setSearch(e.detail.value)
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  searchButton();
                }
              }}
            />

            <IonButton title="Buscar" color="primary" onClick={searchButton}>
              <IonIcon icon={searchOutline} />
            </IonButton>

            <IonButton title="Limpar busca" color="medium" onClick={resetForm}>
              <IonIcon icon={refreshOutline} />
            </IonButton>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <DataTableContainer
              data={data}
              columns={columns}
              paginationPerPage={filter.filter.limit}
              paginationTotalRows={count}
              onRowClicked={(e: any) => params.onRowClicked ? params.onRowClicked(e.id) : edit(e.id)}
              onChangePage={async (page: any, total: any) => {
                const newFilter = changePage(page, total)
                await getData(newFilter)
              }}
              progressPending={loadingDatatable} />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        cssClass='my-custom-class'
        header={'Aviso'}
        message={`Deseja deletar registro <strong>${selectedIdRow}</strong>?`}
        buttons={[{
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            setShowDeleteAlert(false)
          }
        }, {
          text: 'Ok',
          handler: async () => {
            //call delete
            setShowDeleteAlert(false)
            if (params.deleteRow) {
              await params.deleteRow(selectedIdRow)
              await resetForm()
            }
          }
        }
        ]} />
    </> : <Loader />}
  </>
  );
};

export default ApiDataTable;