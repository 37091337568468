import React from 'react';
import './Access.css';
import  {FormatDate} from "../../components/DataTable/DataTableContainer";
import RoutesConsts from "../../consts/RoutesConsts";
import ApiDataTable from '../../components/ApiDataTable/ApiDataTable'
import Page from '../../components/Page/Page';
const Access: React.FC = () => {

    const columns = [
        {
            name: 'Id',
            selector: 'id',
            sortable: true,
        },
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Condominio',
            selector: 'condominium',
            sortable: true,
        },
        {
          name: 'Criado em',
          selector: 'createdAt',
          sortable: true,
          cell: (row: any) => FormatDate(row.createdAt),
        },
        {
          name: 'Atualizado em',
          selector: 'updatedAt',
          sortable: true,
          cell: (row: any) => FormatDate(row.updatedAt),
        }
    ];
    
  return (
      <Page 
        title={'Acesso'}
        backToRoute={RoutesConsts.MAIN}
        >
        <ApiDataTable 
          routeCreate={RoutesConsts.ACCESS_FORM}
          // countService={ApiService.getAuthorizedCount}
          // getService={ApiService.getAuthorized}
          // deleteRow={ApiService.deleteAuthorized}
          columns={columns} />
      </Page>
  );
};

export default Access;