import * as CONSTS from "../const"
import { ApiService } from "./api"

export class DeliveryApiService extends ApiService {

    async createDelivery(delivery: any) {

        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_DELIVERY}`
            const headers = { ...this.httpService.getHeader() }
            const deliveryResponse: any = await this.httpService.post(url, delivery, headers)

            if (!deliveryResponse.data) {
                throw new Error("Error ao criar entrega")
            }

            return deliveryResponse.data
        } catch (error) {
            return error
        }
    }

    async updateDelivery(delivery: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_DELIVERY}/${delivery.id}`
            const headers = { ...this.httpService.getHeader() }
            const deliveryResponse: any = await this.httpService.put(url, delivery, headers)

            if (!deliveryResponse.data) {
                throw new Error("Error ao atualizar entrega")
            }

            return deliveryResponse.data
        } catch (error) {
            return error
        }
    }

    async getDelivery(id: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_DELIVERY}/${id}`
            const params: any = { filter: { include: [{ relation: 'condominium' }, { relation: 'people' }] } }
            const headers = { ...this.httpService.getHeader(), params }
            const deliveryResponse: any = await this.httpService.get(url, headers)

            if (!deliveryResponse.data) {
                throw new Error("Error ao carregar entrega")
            }

            return deliveryResponse.data
        } catch (error) {
            return error
        }
    }

    async getDeliveries(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_DELIVERY}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async getDeliveryCount(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_DELIVERY_COUNT}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async deleteDelivery(id: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_DELIVERY}/${id}`
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.delete(url, headers)

        return response
    }

}
