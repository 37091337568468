import _ from "lodash";
import { useState } from "react";
import { TYPE_AUTO_COMPLETE } from "../components/FormContainer/Field";
import { getAutocompleteName } from "../components/FormContainer/FormEffects";

export const UPDATE_FORM = 'UPDATE_FORM'
export const RESET_FORM = 'RESET_FORM'
export const UPDATE_FORM_FIELDS_VALUES = 'UPDATE_FORM_FIELDS_VALUES'
export const UPDATE_AUTOCOMPLETE_FIELD = 'UPDATE_AUTOCOMPLETE_FIELD'
export const RESET_AUTOCOMPLETE_SEARCH = 'RESET_AUTOCOMPLETE_SEARCH'

const initialState = {}

export const FormReducer = {
    formState: (state: any = initialState, action: any) => {
        switch (action.type) {
            case RESET_FORM:
                return {
                    ...state,
                    [action.name]: {
                        ...state[action.name],
                        formData: {},
                        isSubmitted: false,
                        isPersisted: false,
                        isReadOnly: false
                    }
                }

            case UPDATE_FORM:
                const { type, ...newAction } = action;
                return {
                    ...state,
                    [newAction.name]: { ...state[newAction.name], ...newAction }
                }

            case UPDATE_AUTOCOMPLETE_FIELD:
                const { ...newState } = state
                newState[action.name].fields[action.field.name].options = action.field.options
                return newState

            case RESET_AUTOCOMPLETE_SEARCH:
                let { ...newStateResetOptions } = state
                const fields = _.values(newStateResetOptions[action.name].fields)
                const autocompleteFields = fields.reduce((accFieldName: any, field: any) => {
                    if (field.type == TYPE_AUTO_COMPLETE) {
                        return [...accFieldName, getAutocompleteName(action.name, field.name)]
                    }
                    return accFieldName
                }, [])

                _.each(autocompleteFields, (autocompleteField) => {
                    newStateResetOptions[action.name] = _.omit(newStateResetOptions[action.name], autocompleteField)
                })

                return newStateResetOptions

            case UPDATE_FORM_FIELDS_VALUES:
                const { ...newStateUpdateFormFieldsValues } = state
                newStateUpdateFormFieldsValues[action.name].fields[action.field.name].value = action.field.value
                return newStateUpdateFormFieldsValues

            default:
                return state;
        }
    }
}