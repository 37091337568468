import * as CONSTS from "../const"
import { LocalStorageService } from "../local-storage"
import { HttpService } from "./http"

export class ApiService {
    localStorageService: LocalStorageService
    httpService: HttpService

    constructor(
        localStorageService: LocalStorageService,
        httpService: HttpService
    ) {
        this.localStorageService = localStorageService
        this.httpService = httpService
    }

    getAuthorizationHeader() {
        const userLocalStorage = this.localStorageService.getItem(CONSTS.AUTHORIZED_USER_LOCALSTORAGE_ITEM)
        const Authorization = userLocalStorage?.token || 'no_token'

        const authorizationHeader = {
            Authorization
        }

        return authorizationHeader
    }

    async login(
        email: string,
        password: string
    ): Promise<any> {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_LOGIN}`
        const payload = {
            email,
            password
        }
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.post(url, payload, headers)

        return response
    }

    async whoIam(): Promise<any> {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_WHOIAM}`
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async verifyEmail(email: string) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_VERIFY_EMAIL}/${email}`
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async syncScreenView() {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_SCREENVIEWS}`
            const headers = { ...this.httpService.getHeader() }
            const syncScreenViewResponse: any = await this.httpService.post(url, {}, headers)

            if (!syncScreenViewResponse.data) {
                throw new Error("Error ao sincronizar cameras")
            }

            return syncScreenViewResponse.data
        } catch (error) {
            return error
        }
    }

    async syncPabx() {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_PABX}`
            const headers = { ...this.httpService.getHeader() }
            const syncScreenViewResponse: any = await this.httpService.post(url, {}, headers)

            if (!syncScreenViewResponse.data) {
                throw new Error("Error ao sincronizar pabx")
            }

            return syncScreenViewResponse.data
        } catch (error) {
            return error
        }
    }

    async syncTrunk() {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_PABX_TRUNK}`
            const headers = { ...this.httpService.getHeader() }
            const syncScreenViewResponse: any = await this.httpService.post(url, {}, headers)

            if (!syncScreenViewResponse.data) {
                throw new Error("Error ao sincronizar pabx")
            }

            return syncScreenViewResponse.data
        } catch (error) {
            return error
        }
    }

    async deletePeers() {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_PABX_DELETE}`
            const headers = { ...this.httpService.getHeader() }
            const syncScreenViewResponse: any = await this.httpService.delete(url, headers)

            if (!syncScreenViewResponse.data) {
                throw new Error("Error ao deletar ramais")
            }

            return syncScreenViewResponse.data
        } catch (error) {
            return error
        }
    }
    
    async syncPeopleGroups() {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_PEOPLE_GROUP}`
            const headers = { ...this.httpService.getHeader() }
            const syncScreenViewResponse: any = await this.httpService.post(url, {}, headers)

            if (!syncScreenViewResponse.data) {
                throw new Error("Error ao sincronizar pabx")
            }

            return syncScreenViewResponse.data
        } catch (error) {
            return error
        }
    }

    async fileUpload(file: any): Promise<any> {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_FILES_UPLOAD}`
        const headers = {
            ...this.httpService.getHeader(),
            'Content-Type': 'multipart/form-data'
        }

        const formData = new FormData();
        formData.append('file', file);

        const response = await this.httpService.post(url, formData, headers)

        return response
    }
}