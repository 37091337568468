import * as Yup from "yup";
import { ContainerService } from "../../services/container";

const containerService = new ContainerService().build()
const requiredMessage = 'Informe um email'
const emailMessage = 'Informe um email valido'
const checkEmailMessage = 'Email já foi escolhido'
const checkEmailUsedTestValidationName = 'checkEmailUsed'

export const validationEmailApi =
    Yup
    .string()
    .required(requiredMessage)
    .email(emailMessage)
    .test(checkEmailUsedTestValidationName, checkEmailMessage, async (value) => {
        const valueSanitized = value || "";

            try {
                await containerService.apiService.verifyEmail(valueSanitized)
            } catch (error) {
                const emailAlreadExists = (error as any)?.response?.data?.error

            if (emailAlreadExists) {
                return true
            }
        }
        return false
    });
