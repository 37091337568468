import { AUTHORIZED_USER_LOCALSTORAGE_ITEM } from "../services/const";
import { ContainerService } from "../services/container";

const containerService = new ContainerService().build()

export const UPDATE_USER = 'UPDATE_USER'

const initialState = {
    user: { ...containerService.localStorageService.getItem(AUTHORIZED_USER_LOCALSTORAGE_ITEM) }
};

export const UserReducer = {
    userState: (state = initialState, action: any) => {
        switch (action.type) {
            case UPDATE_USER:
                return {
                    ...state,
                    user: action.user
                };
            default:
                return state;
        }
    }
}