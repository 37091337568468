
import { ContainerService } from '../services/container';

export const LOGGED_IN_STATUS = 'Logged in'
export const LOGGED_OUT_STATUS = 'Logged out'
export const LOGIN_CANCELLED_STATUS = 'Login cancelled'

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_CANCELLED = 'LOGIN_CANCELLED';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const LOGIN_PERFORM = 'PERFORM_LOGIN';
export const LOGIN_RESET = 'LOGIN_RESET'

const initialState: any = {
    status: LOGGED_OUT_STATUS,
    isAuth: false,
    error: false
};

export const LoginReducer = {
    loginState: (state = initialState, action: any) => {
        switch (action.type) {
            case LOGIN_RESET:
                return { ...state, status: LOGGED_OUT_STATUS, isAuth: false, error: false }
            case LOGIN_SUCCESS:
                return { ...state, status: LOGGED_IN_STATUS, isAuth: true, error: false }
            case LOGOUT:
                return { ...state, status: LOGGED_OUT_STATUS, isAuth: false, error: false }
            case LOGIN_ERROR:
                return { ...state, status: action.error, isAuth: false, error: true }
            case LOGIN_CANCELLED:
                return { ...state, status: LOGIN_CANCELLED_STATUS, isAuth: false, error: false }
            default:
                return state
        }
    }
}