const RoutesConsts = {
    ROOT:'/',
    LOGIN:'/login',
    MAIN:'/main',
    CONDOMINIUM: '/condominium',
    CONDOMINIUM_FORM: '/condominium-form',
    DELIVERY: '/delivery',
    DELIVERY_FORM: '/delivery-form',
    ACCESS:'/access',
    ACCESS_FORM:'/access-form',
    PEOPLE:'/people',
    PEOPLE_FORM:'/people-form',
    AUTHORIZED_PERSON:'/autorized-person',
    AUTHORIZED_PERSON_FORM: '/autorized-person-form',
    INVITEES:'/invitees',
    SCHEDULE:'/schedule',
    SCHEDULE_FORM:'/schedule-form',
    MESSAGE_DASHBOARD:'/message-dashboard',
    REQUESTS:'/requests',
    EVENTS:'/events',
    MYQR:'/my-qr',
    ASSISTED_ENTRY:'/assisted-entry',
    EMERGENCY:'/emergency',
    TICKETS:'/tickets',
    TICKETS_FORM:'/tickets-form',
    CONDOMINIUM_DOOR: '/condominium-door',
    CONDOMINIUM_GATEKEEPERS: '/condominium-gatekeepers',
    CONDOMINIUM_GATEKEEPERS_FORM:'/condominium-gatekeepers-form',
    CONDOMINIUM_DOOR_FORM: '/condominium-door-form',
}
export const endPointUrl = 'http://localhost:3000/'
export default RoutesConsts;