import _ from "lodash";
import {RESET_AUTOCOMPLETE_SEARCH, UPDATE_FORM} from "../reducers/form";

export const updateFormValue = (formKey:any, fieldName:any, value:any) => ({
    type: UPDATE_FORM,
    formKey,fieldName,value
})

export const createFormField = (name:string,fields:any) => ({type:UPDATE_FORM, 
    fields, 
    formData: {}, 
    isSubmitted: false,
    isPersisted: false, 
    isReadOnly: false, 
    name})

export const updateFormField = (name:string, formData:any, formState:any) => {
    return  {type:UPDATE_FORM, 
            name, 
            formData,
            ...formState // adiciona estados como isSubmitted: true, 
    }
}

export const updateFormState = (name:string, formState:any) => {
    return  {type:UPDATE_FORM, 
            name, 
            ...formState // adiciona estados como isSubmitted: true, 
    }
}

export const cleanFieldAutocompleteSearch = (name:string) => {
    return  {type: RESET_AUTOCOMPLETE_SEARCH, name}
}

export const updateFormFieldValue = (name:string, formData:any, formFields:any) => {
    const newFormFields = {...formFields}
    _.map(newFormFields, (formDataField:any, formDataFieldIndex:any) => {
        if(formData[formDataFieldIndex]){
            formDataField.value = formData[formDataFieldIndex]
        }

        return formDataField
    })

    return  {type:UPDATE_FORM, 
            name,
            fields:newFormFields // adiciona estados como isSubmitted: true, 
    }
}