import {applyMiddleware, compose, createStore} from 'redux';
import  Reducers  from '../reducers';
import rootSaga from '../saga'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();
// const Store = createStore(Reducers ,  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

let composer = {}
if(window.__REDUX_DEVTOOLS_EXTENSION__){
    composer = compose(applyMiddleware(sagaMiddleware, logger), window.__REDUX_DEVTOOLS_EXTENSION__())
}else{
    composer = compose(applyMiddleware(sagaMiddleware, logger))
}

const Store = createStore(Reducers,composer)
sagaMiddleware.run(rootSaga);

export default Store