import _ from 'lodash';
import { call, put } from 'redux-saga/effects'
import { updateFormField, updateFormFieldValue, updateFormState } from '../actions/form'
import { addNotication } from '../components/Notification/Notification';
import { ContainerService } from '../services/container';
import { UtilsService } from '../services/utils';

export const ACTION_UPSERT_CONDOMINIUM = 'ACTION_UPSERT_CONDOMINIUM'
export const ACTION_GET_CONDOMINIUM = 'ACTION_GET_CONDOMINIUM'
export const ACTION_ERROR_GET_CONDOMINIUM = 'ACTION_ERROR_GET_CONDOMINIUM'
export const ACTION_UPSERT_ERROR_CONDOMINIUM = 'ACTION_UPSERT_ERROR_CONDOMINIUM'

const containerService = new ContainerService().build()

const FORM_NAME = "condominium"

export function* upsertCondominiumFlow(action: any): any {
    try {
        if (UtilsService.formDataNotEmpty(action.formData)) {
            const condominiumPayload: any = {
                ..._.omit(action.formData.condominium, ['peoplesInCondominium', 'manager']),
                managerPeopleId: action?.formData?.condominium?.manager?.id
            }
            const peoplesInCondominium =
                action.formData.condominium.peoplesInCondominium
            const peoplesInCondominiumIds =
                peoplesInCondominium
                    .reduce((accCondominium: any, condominium: any) => [...accCondominium, condominium.id], [])

            let condominium

            if (!condominiumPayload.id) {
                condominium = yield call(
                    [
                        containerService.condominiumApiService,
                        containerService.condominiumApiService.createCondominium
                    ],
                    _.omit(condominiumPayload, 'id')
                )

            } else {
                condominium = yield call(
                    [
                        containerService.condominiumApiService,
                        containerService.condominiumApiService.updateCondominium
                    ],
                    condominiumPayload
                )
            }

            //validar se tem registros antigos
            yield call(
                [
                    containerService.condominiumApiService,
                    containerService.condominiumApiService.createPeoplesInCondominium
                ],
                condominium.id,
                peoplesInCondominiumIds
            )

            yield put(updateFormField(FORM_NAME, {}, { isPersisted: true }))

            const notification = {
                message: "Condominio salvo com sucesso",
                typeNotification: "success"
            }

            yield put(addNotication(notification))
        }
    } catch (error) {
        const notification = {
            message: error,
            typeNotification: "error"
        }

        yield put(addNotication(notification))

        yield put(updateFormState(FORM_NAME, { isPersisted: false, isSubmitted: false }))
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}

export function* getCondominiumFlow(action: any): any {
    try {
        const id: number = action.id
        if (id === 0) return;
        yield put({ type: 'PERSISTING_API' })

        const condominium = yield call([containerService.condominiumApiService, containerService.condominiumApiService.getCondominium], id)
        const condominiumPayload = condominium.data
        const peoplesInCondominium = yield call([containerService.condominiumApiService, containerService.condominiumApiService.getPeoplesInCondominium], id, {})
        const formData = {
            ...condominiumPayload,
            manager: { id: condominiumPayload?.manager?.id, label: condominiumPayload?.manager?.name },
            peoplesInCondominium: peoplesInCondominium.map((people: any) => ({ id: people.id, label: people.name }))
        }

        yield put(updateFormFieldValue(FORM_NAME, formData, action.fields))

    } catch (error) {
        console.log('getCondominiumFlow', error)
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}