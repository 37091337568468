import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './Main.css';
import Page from '../../components/Page/Page';

import {
    IonGrid, IonRow, IonCol, IonIcon, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle, IonRouterLink
} from '@ionic/react'; 

import { businessOutline, peopleOutline, keyOutline, logOutOutline, phoneLandscapeOutline, keypadOutline } from "ionicons/icons";

//import FullCalendar from '@fullcalendar/react'
//import dayGridPlugin from '@fullcalendar/daygrid'
import { useHistory } from 'react-router';
import { LAST_LOGIN_EMAIL_LOCALSTORAGE_ITEM } from '../../services/const';
import { LocalStorageService } from '../../services/local-storage';
import { LOGOUT } from '../../reducers/login';
import RoutesConsts from '../../consts/RoutesConsts';
//import { CircularProgress } from '@material-ui/core';
import Loader from '../../components/Loader/Loader';
import { ContainerService } from '../../services/container';


const containerService = new ContainerService().build()

const Main: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    const localStorageService = new LocalStorageService();
    
    const [notRendered, setNotRendered] = useState(false);
    const [events, setEvents] = useState([]);

    const doLogoff = () => {
        const lastLoginEmail = localStorageService.getItem(LAST_LOGIN_EMAIL_LOCALSTORAGE_ITEM);
        localStorageService.clear();
        localStorageService.setItem(LAST_LOGIN_EMAIL_LOCALSTORAGE_ITEM, lastLoginEmail);

        dispatch({ type: LOGOUT });
        history.push(RoutesConsts.LOGIN);
    }

    const doRoute = (route: string) => {
        history.push(route)
    }

    /*
    useEffect(() => {
        setTimeout(async () => {
            setNotRendered(true)
            const filter = {}
            const schedulesResponse: any = await containerService.scheduleApiService.getSchedules(filter)
            const reducedEvents = schedulesResponse.data.reduce((accumalatorSchedule: any, schedule: any) => {
                return [...accumalatorSchedule,
                {
                    title: schedule.description,
                    start: schedule.scheduleStartAt,
                    url: `${RoutesConsts.SCHEDULE_FORM}/${schedule.id}`, // trocar por id
                }]
            }, [])

            setEvents(reducedEvents)
        }, 1000)
    }, [])
    */

    // const fields = {
    //                 "id":{type:TYPE_ID, name: "id", row:1, group: "Pessoas", label:"ID", value:1, validation: Yup.number().required()},
    //                 "name":{type:TYPE_TEXT, name: "name", row:1, group: "Pessoas", label:"Nome", value:"text", validation: Yup.string().required()},
    //                 "obs":{type:TYPE_TEXT, name: "obs", row:1, label:"Obs", value:"obs2", multiline:true, validation: Yup.string().required()},
    //                 "socialId":{type:TYPE_TEXT, name: "socialId", row:1,group: "Pessoas", label:"Documento", value:"1", validation:null},
    //                 "userId":{type:TYPE_ID, name: "userId", row:1, group: "Usuarios", label:"ID Usuario", value:"1", validation:null},

    //                 "email":{type:TYPE_TEXT, name: "email", row:1, group: "Usuarios", label:"Email", value:"text@text.com", validation: validationEmailApi},

    //                 "password":{type:TYPE_PASSWORD, name: "password", row:1, group: "Usuarios", label:"Senha", value:"text", validation: Yup.string().required()},
    //                 "test":{type:TYPE_SELECT, name: "test", row:1, label:"Teste", value:"1", options: [{label:"teste 1", value:"1"}, {label:"teste 2", value:"2"}], validation: Yup.string().required()},
    //                 "test2":{type:TYPE_RADIO, name: "test2", row:1, label:"Teste2", value:"1", options: [{label:"teste 1", value:"1"}, {label:"teste 2", value:"2"} , {label:"teste 3", value:"3"} , {label:"teste 4", value:"4"}], validation: Yup.string().required()},
    //                 "datetimepicker":{type:TYPE_DATETIMEPICKER, name:"datetimepicker", row:1, label:"Datetimepicker", value:moment().toDate(), format:"dd/MM/yyyy", validation: Yup.string().required()},
    //                 "timepicker":{type:TYPE_TIMEPICKER, name:"timepicker", row:1, label:"Timepicker", value:moment().toDate(), format:"HH:mm", validation: Yup.string().required()},
    //                 "datepicker":{type:TYPE_DATEPICKER, name:"timdatepickerepicker", row:1, label:"Datepicker", value:moment().toDate(), format:"HH:mm", validation: Yup.string().required()},
    //                 "toggle":{type:TYPE_TOGGLE, name:"toggle", row:1, label:"Sim/Nao", value: true, validation: null},
    //                 "autocomplete":{type:TYPE_AUTO_COMPLETE, name: "autocomplete", row:1, group: "Pessoas", label:"Autocomplete", options:[{id:1,label: "pica"}, {id:2,label: "pica2"}], value:{id:1,label: "pica"}, validation: Yup.string().required()},
    //                 "autocomplete2":{type:TYPE_AUTO_COMPLETE, name: "autocomplete2", row:1, group: "Pessoas", label:"Autocomplete-Multi2", multiple: true, options:[{id:1,label: "pica"}, {id:2,label: "pica2"}], value:[{id:2,label: "pica2"}], validation: Yup.string().required()},
    //                 "autocomplete3":{type:TYPE_AUTO_COMPLETE, name: "autocomplete3", row:1, group: "Pessoas", label:"Autocomplete-Multi3 async", async:true, multiple: true, options:[], value:[{id:2,label: "pica2"}], validation: Yup.string().required()},

    //               }
    return (
        <Page title={'Início'}>
            <IonGrid id="grid-main" fixed={true} className="ion-flex ion-flex-column ion-justify-content-center" style={{ height: '100%' }}>
                <IonRow>
                    <IonCol>
                        <IonRouterLink href="javascript:;" onClick={e => doRoute(RoutesConsts.CONDOMINIUM)}>
                            <IonCard color="light">
                                <IonIcon
                                    style={{ fontSize: "100px", color: "#2c3e50" }}
                                    icon={businessOutline}
                                />

                                <IonCardHeader>
                                    <IonCardTitle>Condomínio</IonCardTitle>
                                    <IonCardSubtitle>Gerenciamento de condomínios</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                </IonCardContent>
                            </IonCard>
                        </IonRouterLink>
                    </IonCol>

                    <IonCol>
                        <IonRouterLink href="javascript:;" onClick={e => doRoute(RoutesConsts.CONDOMINIUM_DOOR)}>
                            <IonCard color="light">
                                <IonIcon
                                    style={{ fontSize: "100px", color: "#2c3e50" }}
                                    icon={keyOutline}
                                />

                                <IonCardHeader>
                                    <IonCardTitle>Portas</IonCardTitle>
                                    <IonCardSubtitle>Gerenciamento de portas</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                </IonCardContent>
                            </IonCard>
                        </IonRouterLink>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonRouterLink href="javascript:;" onClick={e => doRoute(RoutesConsts.PEOPLE)}>
                            <IonCard color="light">
                                <IonIcon
                                    style={{ fontSize: "100px", color: "#2c3e50" }}
                                    icon={peopleOutline}
                                />

                                <IonCardHeader>
                                    <IonCardTitle>Pessoas</IonCardTitle>
                                    <IonCardSubtitle>Gerenciamento de pessoas</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                </IonCardContent>
                            </IonCard>
                        </IonRouterLink>
                    </IonCol>

                    <IonCol>
                        <IonRouterLink href="javascript:;" onClick={e => doRoute(RoutesConsts.CONDOMINIUM_GATEKEEPERS)}>
                            <IonCard color="light">
                                <IonIcon
                                    style={{ fontSize: "100px", color: "#2c3e50" }}
                                    icon={keypadOutline}
                                />

                                <IonCardHeader>
                                    <IonCardTitle>Interfones</IonCardTitle>
                                    <IonCardSubtitle>Gerenciamento de interfones</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                </IonCardContent>
                            </IonCard>
                        </IonRouterLink>
                    </IonCol>
                </IonRow>
            </IonGrid>
            {/* <Button variant="contained" 
                        color="primary"  
                        onClick={e=>{ 
                        const notification = {message: "wololo", typeNotification: "success"}
                        dispatch(addNotication(notification))
                        }}>Teste</Button> */}

            {/* <ModalContainer>
                <div style={{overflowY:"auto",overflowX:"hidden"}}>
                <FormContainer name={'people2'} chunckRender={false} />  // usa render de row 
                </div>
            </ModalContainer> */}

            {/*notRendered ? <FullCalendar
                plugins={[ dayGridPlugin ]}
                initialView="dayGridMonth"
                events={events}
                eventClick={(info) => {
                    info.jsEvent.preventDefault(); // don't let the browser navigate

                    if (info.event.url) {
                        history.push(info.event.url)
                    }
                  }}
            /> : <Loader/>*/}

        </Page>
    );
};

export default Main;
