import _ from 'lodash';
import { call, put } from 'redux-saga/effects'
import { updateFormField, updateFormFieldValue, updateFormState } from '../actions/form'
import { addNotication } from '../components/Notification/Notification';
import { SECRET_SIP } from '../services/const';
import { ContainerService } from "../services/container";
import { UtilsService } from '../services/utils';

export const ACTION_UPSERT_PEOPLE = 'ACTION_UPSERT_PEOPLE'
export const ACTION_GET_PEOPLE = 'ACTION_GET_PEOPLE'
export const ACTION_ERROR_GET_PEOPLE = 'ACTION_ERROR_GET_PEOPLE'
export const ACTION_UPSERT_ERROR_PEOPLE = 'ACTION_UPSERT_ERROR_PEOPLE'

const containerService = new ContainerService().build()

const FORM_NAME = "people"

export function* upsertPeopleFlow(action: any): any {
    try {
        if (UtilsService.formDataNotEmpty(action.formData)) {
            const userPayload = action.formData.user
            const condominiumWhereILiveIds = action.formData.people.condominiumWhereILive.reduce((accCondominium: any, condominium: any) => [...accCondominium, condominium.id], [])

            let people

            let peoplePayload = action.formData.people
            
            delete peoplePayload.condominiumWhereILive

            if (!peoplePayload.id) {
                delete peoplePayload.id

                const user = yield call(
                    [
                        containerService.peopleApiService,
                        containerService.peopleApiService.createUser,
                    ],
                    userPayload
                )
                
                peoplePayload.userId = user.id
                
                people = yield call(
                    [
                        containerService.peopleApiService,
                        containerService.peopleApiService.createPeople
                    ],
                    peoplePayload
                )

            } else {
                people = yield call(
                    [
                        containerService.peopleApiService,
                        containerService.peopleApiService.updatePeople
                    ],
                    peoplePayload
                )
                if (userPayload.password) {
                    yield call(
                        [
                            containerService.peopleApiService,
                            containerService.peopleApiService.updateUserPassword
                        ],
                        people.userId,
                        userPayload.password
                    )
                }
            }

            yield call(
                [
                    containerService.peopleApiService,
                    containerService.peopleApiService.admin
                ],
                people.userId,
                userPayload.roles
            )

            //validar se tem registros antigos
            yield call(
                [
                    containerService.peopleApiService,
                    containerService.peopleApiService.createCondominiumsWhereILive
                ],
                people.id,
                condominiumWhereILiveIds
            )

            yield put(updateFormField(FORM_NAME, {}, { isPersisted: true }))

            const notification = {
                message: "Usuario salvo com sucesso",
                typeNotification: "success"
            }

            yield put(addNotication(notification))
        }
    } catch (error) {

        const notification = {
            message: error,
            typeNotification: "error"
        }

        yield put(addNotication(notification))

        //LIMPAR FORM
        yield put(updateFormState(FORM_NAME, { isPersisted: false, isSubmitted: false }))
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}

export function* getPeopleFlow(action: any): any {
    try {
        const id: number = action.id
        if (id === 0) return;
        yield put({ type: 'PERSISTING_API' })

        const peopleUser = yield call(
            [
                containerService.peopleApiService,
                containerService.peopleApiService.getPeople
            ],
            id
        )
        const condominiumWhereILive = yield call(
            [
                containerService.peopleApiService,
                containerService.peopleApiService.getCondominiumsWhereILive
            ],
            id,
            {}
        )

        const user = _.omit(peopleUser.user, 'id') ?? null
        user.roles = user.roles && user.roles.length > 0 ? true : false
        const people = _.omit(peopleUser, 'user')
        
        const [condominium] = condominiumWhereILive
        const condominiumId = condominium && condominium.id ? condominium.id : null

        const sip: any = {
            extension_sip: '',
            secret_sip: ''
        }

        if(condominiumId) {
            sip.extension_sip = `condominium_${condominiumId}_people_${peopleUser.id}`
            sip.secret_sip = SECRET_SIP
        }

        const formData = {
            ...people,
            ...user,
            ...sip,
            condominiumWhereILive: condominiumWhereILive.map((condominium: any) => ({ id: condominium.id, label: condominium.name }))
        }

        yield put(updateFormFieldValue(FORM_NAME, formData, action.fields))

    } catch (error) {
        console.log('getPeopleFlow', error)
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}