import _ from 'lodash';
import { call, put } from 'redux-saga/effects'
import { updateFormField, updateFormFieldValue, updateFormState } from '../actions/form'
import { addNotication } from '../components/Notification/Notification';
import { ContainerService } from '../services/container';
import { UtilsService } from '../services/utils';

export const ACTION_UPSERT_AUTHORIZED_PEOPLE = 'ACTION_UPSERT_AUTHORIZED_PEOPLE'
export const ACTION_GET_AUTHORIZED_PEOPLE = 'ACTION_GET_AUTHORIZED_PEOPLE'
export const ACTION_ERROR_UPSERT_AUTHORIZED_PEOPLE = 'ACTION_ERROR_UPSERT_AUTHORIZED_PEOPLE'
export const ACTION_ERROR_GET_AUTHORIZED_PEOPLE = 'ACTION_ERROR_GET_AUTHORIZED_PEOPLE'

const containerService = new ContainerService().build()

const FORM_NAME = "authorized_people"

export function* upsertAuthorizedPeopleFlow(action: any) {
    try {
        if (UtilsService.formDataNotEmpty(action.formData)) {
            const { authorized_people } = action.formData //nao esta vindo o user id
            const authorizedPeoplePayload = {
                id: authorized_people.id,
                condominiumId: authorized_people.condominiumId.id,
                peopleId: authorized_people.peopleId.id,
                parentPeopleId: authorized_people.parentPeopleId.id
            }

            if (!authorizedPeoplePayload.id) {
                yield call(
                    [
                        containerService.authorizedPersonApiService,
                        containerService.authorizedPersonApiService.createAuthorizedPeople
                    ], _.omit(authorizedPeoplePayload, "id"))
            } else {
                yield call(
                    [
                        containerService.authorizedPersonApiService,
                        containerService.authorizedPersonApiService.updateAuthorizedPeople
                    ],
                    authorizedPeoplePayload
                )
            }

            yield put(updateFormField(FORM_NAME, {}, { isPersisted: true }))

            const notification = {
                message: "Pessoa Authorizada salvo com sucesso",
                typeNotification: "success"
            }

            yield put(addNotication(notification))
        }

    } catch (error) {

        const notification = {
            message: error,
            typeNotification: "error"
        }

        yield put(addNotication(notification))

        //LIMPAR FORM
        yield put(updateFormState(FORM_NAME, { isPersisted: false, isSubmitted: false }))
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}

export function* getAuthorizedPeopleFlow(action: any): any {
    try {
        const id: number = action.id
        if (id === 0) return;
        yield put({ type: 'PERSISTING_API' })

        const authorizedPeople = yield call(
            [
                containerService.authorizedPersonApiService,
                containerService.authorizedPersonApiService.getAuthorizedPeople
            ],
            id
        )

        const authorizedPeopleResponse = {
            ...authorizedPeople,
            condominiumId: {
                id: authorizedPeople.condominium.id,
                label: `${authorizedPeople.condominium.id} - ${authorizedPeople.condominium.name}`
            },
            peopleId: {
                id: authorizedPeople.people.id,
                label: `${authorizedPeople.people.id} - ${authorizedPeople.people.name}`
            },
            parentPeopleId: {
                id: authorizedPeople.parentPeople.id,
                label: `${authorizedPeople.parentPeople.id} - ${authorizedPeople.parentPeople.name}`
            },
        }

        yield put(updateFormFieldValue(FORM_NAME, authorizedPeopleResponse, action.fields))

    } catch (error) {
        console.log('getAuthorizedPeopleFlow', error)
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}