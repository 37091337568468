import { call, cancel, cancelled, fork, put, take } from 'redux-saga/effects';
import { LOGIN_CANCELLED, LOGIN_ERROR, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from "../reducers/login";
import { UPDATE_USER } from '../reducers/user';
import { ContainerService } from '../services/container';
import { AUTHORIZED_USER_LOCALSTORAGE_ITEM, LAST_LOGIN_EMAIL_LOCALSTORAGE_ITEM } from "../services/const";



export function* authorizeFlow(email: string, password: string): any {
    const containerService = new ContainerService().build()
    try {
        //Invoca servico de autorização, caso na promnise retorne error ele dispara throw e cai no catch.
        //passar o contexto e o metodo para nao ficar null
        yield call(
            [
                containerService,
                containerService.authService.authenticate
            ],
            email,
            password
        )

        containerService.localStorageService.setItem(LAST_LOGIN_EMAIL_LOCALSTORAGE_ITEM, email)
        //se passou aqui não deu exception, logo muda o estado para LOGIN_SUCCESS
        yield put({ type: LOGIN_SUCCESS })

        const whoIam = yield call(
            [
                containerService.authService,
                containerService.authService.whoIam
            ])

        if (whoIam.roles.length == 0) {
            throw Error("Este usuario não tem permissão")
        }

        containerService.localStorageService.setItem(AUTHORIZED_USER_LOCALSTORAGE_ITEM, whoIam)

        yield put({ type: UPDATE_USER, user: whoIam })
    } catch (error) {
        yield put({ type: LOGIN_ERROR, error: error })
    } finally {
        if (yield cancelled()) {
            yield put({ type: LOGIN_CANCELLED })
        }
    }
}

export function* loginFlow(): any {
    try {
        while (true) {
            //pega email  e password passados pela action
            const { email, password } = yield take(LOGIN_REQUEST)
            //envia para o fluxo de autorização
            const authorizeFlowTask = yield fork(authorizeFlow, email, password)
            //apos isso ficara trancado esperando logout ou login_error
            const action = yield take([LOGOUT, LOGIN_ERROR])
            if (action.type === 'LOGOUT') {
                //cancela fluxo de authorizacao - libera ponteiro do saga
                yield cancel(authorizeFlowTask)
            }
        }
    } catch (ex) {
        console.log('loginFlow', ex)
    }
}