import _ from "lodash";

export class UtilsService {
    static formDataNotEmpty(formData: any): boolean {
        return !_.isEmpty(formData)
    }

    static reloadPage() {
        document.location.reload();
    }

    static isLoginPage() {
        return document.location.hash.includes('/login')
    }
}
