import React from 'react';
import { IonCard, IonGrid, IonCol, IonIcon, IonRow, IonButton, IonToolbar, IonButtons, IonHeader, IonPopover, IonContent, IonBadge, IonList, IonItem, IonLabel } from '@ionic/react';
import './People.css';
import { FormatDate } from "../../components/DataTable/DataTableContainer";
import RoutesConsts from "../../consts/RoutesConsts";
import { ContainerService } from '../../services/container';
import ApiDataTable from '../../components/ApiDataTable/ApiDataTable'
import Page from '../../components/Page/Page';
import { videocamOutline } from 'ionicons/icons';
import { Button } from '@material-ui/core';

const People: React.FC = () => {

  const columns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      wrap: true
    },
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
      wrap: true
    },
    {
      name: 'Contato',
      selector: 'phone',
      sortable: true,
      wrap: true
    },
    {
      name: 'Interfone',
      selector: 'extension',
      sortable: true,
      wrap: true
    },
    {
      name: 'Condomínio',
      selector: 'condominiums',
      sortable: true,
      wrap: true,
      cell: (row: any) => {
        const condominiums = row.condominiums.split(',');
        if(condominiums.length > 1) {
          return <>
            {condominiums.join(';')}
          </>
        }

        return condominiums[0]
      }
    },
    {
      name: 'Atualizado em',
      selector: 'updatedAt',
      sortable: true,
      wrap: true,
      cell: (row: any) => FormatDate(row.updatedAt)
    }
  ];
  
  const include: any = [{ relation: 'condominiumWhereILive' }]
  const containerService = new ContainerService().build()
  return (
    <Page
      title={'Pessoas'}
      backToRoute={RoutesConsts.MAIN}
      tools={
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton expand="block" color="medium" fill="solid" onClick={() => containerService.apiService.syncPeopleGroups()}>
                <IonIcon slot="start" icon={videocamOutline}></IonIcon> Sincronizar Grupo Ramais 
              </IonButton>
              <IonButton expand="block" color="medium" fill="solid" onClick={() => containerService.apiService.syncPabx()}>
                <IonIcon slot="start" icon={videocamOutline}></IonIcon> Sincronizar Pabx
              </IonButton>
              <IonButton expand="block" color="medium" fill="solid" onClick={() => containerService.apiService.syncTrunk()}>
                <IonIcon slot="start" icon={videocamOutline}></IonIcon> Sincronizar Tronco
              </IonButton>
              <IonButton expand="block" color="medium" fill="solid" onClick={() => containerService.apiService.deletePeers()}>
                <IonIcon slot="start" icon={videocamOutline}></IonIcon> Deletar Ramais
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      }
    >
      <IonGrid fixed={true} className="ion-flex ion-flex-column ion-justify-content-center">
        <IonRow>
          <IonCol>
            <IonCard>
              <ApiDataTable            
                textNew='Nova Pessoa'
                routeCreate={RoutesConsts.PEOPLE_FORM}
                countService={(params: any) => containerService.peopleApiService.getPeoplesCount(params)}
                getService={(filter: any) => containerService.peopleApiService.getPeoples(filter)}
                deleteRow={(filter: any) => containerService.peopleApiService.deletePeoples(filter)}
                columns={columns}
                />
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Page>
  );
};

export default People;