import React from 'react';
import './Condominium.css';
import { FormatDate } from "../../components/DataTable/DataTableContainer";
import RoutesConsts from "../../consts/RoutesConsts";
import { ContainerService } from '../../services/container';
import ApiDataTable from '../../components/ApiDataTable/ApiDataTable'
import Page from '../../components/Page/Page';
import { videocamOutline } from 'ionicons/icons';
import { IonIcon, IonCol, IonGrid, IonCard, IonCardTitle, IonCardContent, IonCardHeader } from '@ionic/react';
import { Button } from '@material-ui/core';

const Condominium: React.FC = () => {
  const columns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Condominio',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Sindico',
      selector: 'manager.name',
      sortable: true,
      cell: (row: any) => row?.manager?.name || "Sem sindico"
    },
    {
      name: 'Telefonia',
      selector: 'phone',
      sortable: true,
      cell: (row: any) => row?.phone == "Y" ? "Sim" : "Não" || "Não"
    },
    {
      name: 'Imobiliaria',
      selector: 'realState',
      sortable: true,
    },
    {
      name: 'Criado em',
      selector: 'createdAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.createdAt),
    },
    {
      name: 'Atualizado em',
      selector: 'updatedAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.updatedAt),
    }
  ];
  const include: any = [{ relation: 'manager' }]
  const containerService = new ContainerService().build()

  return (
    <Page title={'Condominios'} backToRoute={RoutesConsts.MAIN}>
      <IonGrid fixed={true} className="ion-flex ion-flex-column ion-justify-content-center" style={{ height: '100%' }}>
        <IonCol>
          <IonCard>
            <IonCardContent>
              <ApiDataTable
                textNew='Novo Condomínio'
                include={include}
                routeCreate={RoutesConsts.CONDOMINIUM_FORM}
                countService={(params: any) => containerService.condominiumApiService.getCondominiumsCount(params)}
                getService={(filter: any) => containerService.condominiumApiService.getCondominiums(filter)}
                deleteRow={(selectedIdRow: any) => containerService.condominiumApiService.deleteCondominium(selectedIdRow)}
                columns={columns} />
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonGrid>
      {/*
        <Button variant="contained" color="primary" style={{padding: "10px", margin: "5px"}}  onClick={e => containerService.apiService.syncScreenView()}>
          <IonIcon icon={videocamOutline} style={{marginRight:"5px"}} /> Sincronizar Mosaicos
        </Button>
      */}
    </Page>

  );
};

export default Condominium;