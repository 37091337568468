import React, {useEffect} from 'react';
import './ScheduleForm.css';
import RoutesConsts from "../../consts/RoutesConsts";
import FormContainer from "../../components/FormContainer/FormContainer";
import {
  TYPE_AUTO_COMPLETE,
  TYPE_DATETIMEPICKER,
  TYPE_ID,
  TYPE_TEXT,
} from "../../components/FormContainer/Field"
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Page from '../../components/Page/Page';
import _ from 'lodash';
import { getSchedule, upsertSchedule } from '../../actions';
import { cleanFieldAutocompleteSearch, createFormField } from '../../actions/form';
import { useHistory } from 'react-router';
import { ContainerService } from '../../services/container';
import { cleanForm, formHasPersisted, formHasSubmitted, getAutocompleteFieldValue, getFormUseEffectArray, submitForm, updatedAutocompleteOptions } from '../../components/FormContainer/FormEffects';

const containerService = new ContainerService().build()

const ScheduleForm: React.FC = (params: any) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const formStore = useSelector((state: any) => state.formState)

  const id: number = parseInt(params.match.params.id)
  const name = 'schedule'
  const backToRoute = RoutesConsts.SCHEDULE
  const title = 'Agendamentos'

  const isEdit = () => {
    return id && id !== 0
  }

  //colocar em actions/index.tsx
  const loadForm = (id: number) => {
    const fields = {
      "id": { type: TYPE_ID, name: "id", row: 1, group: "schedule", label: "Cod. Pessoa", offset: 0, value: 0, validation: null },
      "peopleId": { type: TYPE_AUTO_COMPLETE, name: "peopleId", row: 1, offset: 0, group: "schedule", options: [], async: true, multiple: false, label: "Criado por", value: null, validation: Yup.object().nullable().required('Selecione alguma pessoa') },
      "scheduleStartAt": { type: TYPE_DATETIMEPICKER, name: "scheduleStartAt", row: 2, offset: 0, format: "dd/MM/yyyy HH:mm", group: "schedule", label: "Incia em", value: "", validation: Yup.string().required('Insira uma data de inicio') },
      "scheduleEndAt": { type: TYPE_DATETIMEPICKER, name: "scheduleEndAt", row: 2, offset: 0, format: "dd/MM/yyyy HH:mm", group: "schedule", label: "Termina em", value: "", validation: Yup.string().required('Insira uma data de fim') },
      "description": { type: TYPE_TEXT, multiline: true, name: "description", row: 3, offset: 1, group: "schedule", label: "Descrição", value: "", validation: Yup.string().required('Insira uma descrição') },
    }

    //carrega form no redux - se nao colocar nao será renderizado pelo componente
    dispatch(createFormField(name, fields))

    if (isEdit()) {
      //altera campos de acordo com a edição
      console.log("Carregando dados do id", id)
      dispatch(getSchedule(id, fields))
    }

  }

  const fetchAutocompletePeople = async (peopleValue: any) => {
    const filter = {
      filter: {
        where: {
          name: { like: `${peopleValue}%` }
        }
      }
    }

    const people: any = await containerService.peopleApiService.getPeoples(filter)
    if (people.data) {
      return people.data.reduce((accPeople: any, people: any) =>
        [...accPeople, { id: people.id, label: `${people.id} - ${people.name}` }], [])
    }
    return []
  }
  //init
  useEffect(() => {
    //Carrega dados do formulario para redux
    //para cada form é necessario um dispatch para criar os dados no redux
    loadForm(id)

    return () => {
      //unmount
      dispatch(cleanFieldAutocompleteSearch(name))
    }
  }, [])

  //atualização de form
  useEffect(() => {
    //verificar se form data mudou
    if (formHasSubmitted(formStore, name)) {
      //envia para saga de criação de pessoas (cria usuario se for novo)
      submitForm(dispatch, formStore, name, upsertSchedule)
    }
    //se o upsert mudar o formulario pra persister quer dizer que inseriu o dado ok
    if (formHasPersisted(formStore, name)) {
      //limpa estado do form - tentar colocar no unmount
      cleanForm(dispatch, name)
      history.push(backToRoute)
    }
    // autocomplete condominium
    if (getAutocompleteFieldValue(formStore, name, "peopleId")) {
      updatedAutocompleteOptions(dispatch, formStore, name, "peopleId", fetchAutocompletePeople)
    }
  }, getFormUseEffectArray(formStore, name, [getAutocompleteFieldValue(formStore, name, "peopleId")]))

  return (
    <Page title={title} backToRoute={backToRoute}>
      <FormContainer name={name} />
    </Page>
  )
};

export default ScheduleForm;
