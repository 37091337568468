import React from 'react';
import './AuthorizedPerson.css';
import { FormatDate } from "../../components/DataTable/DataTableContainer";
import RoutesConsts from "../../consts/RoutesConsts";
import { ContainerService } from '../../services/container';
import ApiDataTable from '../../components/ApiDataTable/ApiDataTable'
import Page from '../../components/Page/Page';

const Access: React.FC = () => {

  const columns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Autorizado',
      selector: 'people.name',
      sortable: true,
      cell: (row: any) => row?.people?.name || "Sem pessoa autorizada",
    },
    {
      name: 'Responsavel',
      selector: 'parentPeople.name',
      sortable: true,
      cell: (row: any) => row?.parentPeople?.name || "Sem responsavel",
    },
    {
      name: 'Condominio',
      selector: 'condominium.name',
      sortable: true,
      cell: (row: any) => row?.condominium?.name || "Sem condominio relacionado",
    },
    {
      name: 'Criado em',
      selector: 'createdAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.createdAt),
    },
    {
      name: 'Atualizado em',
      selector: 'updatedAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.updatedAt),
    }
  ];

  const include: any = [{ relation: 'people' }, { relation: 'parentPeople' }, { relation: 'condominium' }]

  const containerService = new ContainerService().build()

  return (
    <Page
      title={'Pessoas Autorizadas'}
      backToRoute={RoutesConsts.MAIN}
    >
      <ApiDataTable
        include={include}
        routeCreate={RoutesConsts.AUTHORIZED_PERSON_FORM}
        countService={(params: any) => containerService.authorizedPersonApiService.getAuthorizedPeopleCount(params)}
        getService={(filter: any) => containerService.authorizedPersonApiService.getAuthorizedPeoples(filter)}
        deleteRow={(filter: any) => containerService.authorizedPersonApiService.deleteAuthorizedPeople(filter)}
        columns={columns} />
    </Page>
  );
};

export default Access;