import React, { useEffect } from 'react';
import './DoorForm.css';
import RoutesConsts from "../../consts/RoutesConsts";
import FormContainer from "../../components/FormContainer/FormContainer";
import {
  TYPE_AUTO_COMPLETE,
  TYPE_DATETIMEPICKER,
  TYPE_ID,
  TYPE_SELECT,
  TYPE_TEXT,
} from "../../components/FormContainer/Field"
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Page from '../../components/Page/Page';
import _ from 'lodash';
import { getDoor, upsertDoor } from '../../actions';
import { cleanFieldAutocompleteSearch, createFormField } from '../../actions/form';
import { useHistory } from 'react-router';
import { ApiService } from '../../services/api/api';
import { cleanForm, formHasPersisted, formHasSubmitted, getAutocompleteFieldValue, getFormUseEffectArray, submitForm, updatedAutocompleteOptions } from '../../components/FormContainer/FormEffects';
import { ContainerService } from '../../services/container';


const DoorForm: React.FC = (params: any) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const formStore = useSelector((state: any) => state.formState)

  const id: number = parseInt(params.match.params.id)
  const name = 'door'
  const backToRoute = RoutesConsts.CONDOMINIUM_DOOR
  const title = 'Portas'

  const containerService = new ContainerService().build()

  const isEdit = () => {
    return id && id !== 0
  }

  //colocar em actions/index.tsx
  const loadForm = (id: number) => {
    const fields = {
      "id": { type: TYPE_ID, name: "id", row: 1, group: "door", label: "Cod. Porta", offset: 0, value: 0, validation: null },
      "condominiumId": { type: TYPE_AUTO_COMPLETE, name: "condominiumId", row: 1, offset: 0, group: "door", options: [], async: true, multiple: false, updateValue: true, label: "Condominio", value: null, validation: Yup.object().nullable().required('Selecione algum condominio') },
      "item": { type: TYPE_TEXT, name: "item", row: 2, group: "door", label: "Item", value: "", validation: Yup.string().required('Digite algum item') },
      "label": { type: TYPE_TEXT, name: "label", row: 2, group: "door", label: "Label", value: "", validation: Yup.string().required('Digite algum label') },
      "cameraRef": { type: TYPE_AUTO_COMPLETE, name: "cameraRef", row: 3, offset: 0, group: "door", options: [], async: true, multiple: false, label: "Camera", value: null, validation: null },
      "type": { type: TYPE_SELECT, name: "type", row: 3, offset: 0, group: "door", label: "Tipo", options: [{ value: "GARAGEM", label: "Garagem" }, { value: "PORTA", label: "Porta" }], value: "", validation: null },
      "type_share": { type: TYPE_SELECT, name: "type_share", row: 4, offset: 0, group: "door", label: "Tipo Compartilhamento", options: [{ value: "qr", label: "QR code" }, { value: "text", label: "Texto" }], value: "", validation: null },
      "private": { type: TYPE_SELECT, name: "private", row: 4, offset: 0, group: "door", label: "Privado?", options: [{ value: "Y", label: "Sim" }, { value: "N", label: "Não" }], value: "", validation: null },
      "peoplesInDoor": { type: TYPE_AUTO_COMPLETE, name: "peoplesInDoor", row: 5, offset: 1, group: "door_people", options: [], async: true, multiple: true, label: "Pessoas", value: [], validation: null},
    }

    //carrega form no redux - se nao colocar nao será renderizado pelo componente
    dispatch(createFormField(name, fields))

    if (isEdit()) {
      //altera campos de acordo com a edição
      console.log("Carregando dados do id", id)
      dispatch(getDoor(id, fields))
    }

  }

  const fetchAutocompleteCondominium = async (doorValue: any) => {
    const filter: any = {
      filter: {
        where: {
          name: { like: `${doorValue}%` },
          deletedAt: {
            eq: null
          }
        }
      }
    }

    const condominium: any = await containerService.condominiumApiService.getCondominiums(filter)
    if (condominium.data) {
      return condominium.data.reduce((accPeople: any, condominium: any) =>
        [...accPeople, { id: condominium.id, label: `${condominium.id} - ${condominium.name}` }], [])
    }
    return []
  }

  const fetchAutocompleteCamera = async (doorValue: any) => {
    let selectedCamera
    
    if (formStore.door?.fields?.condominiumId?.value) {
      selectedCamera = formStore.door?.fields?.condominiumId?.value
    }else if (formStore.door?.fields?.condominiumId?.options.length) {
      selectedCamera = formStore.door?.fields?.condominiumId?.options.at(0)
    }

    const cameraId = selectedCamera?.id

    if (cameraId) {
      const camera: any =
        await containerService.condominiumApiService.getCamerasByCondominium(cameraId)

      const reducedCameras: any =
        camera.data.
          reduce((accumulatorPeople: any, camera: any) =>
            [
              ...accumulatorPeople,
              {
                id: camera.name,
                label: `${camera.name}`
              }
            ], [])

      return reducedCameras
      
    }
    return []
  }

  const fetchAutocompletePeopleInDoor = async (peopleValue: any) => {
    const condominiumId = formStore.door?.fields?.condominiumId?.value?.id
    let people: any
    let filter

    if(condominiumId) {
      filter = {condominiumId, name: peopleValue}

      people = 
        await containerService.peopleApiService.getPeoplesByCondominium(filter)
    } else {
      filter = {
        filter: {
          where: {
            name: { like: `%${peopleValue}%` },
            deletedAt: {
              eq: null
            }
          }
        }
      }
  
      people = 
        await containerService.peopleApiService.getPeoples(filter)
    }
    
    if (people.data) {
      return people.data.reduce((accPeople: any, people: any) =>
        [...accPeople, { id: people.id, label: `${people.id} - ${people.name}` }], [])
    }

    return []
  }

  //init
  useEffect(() => {
    //Carrega dados do formulario para redux
    //para cada form é necessario um dispatch para criar os dados no redux
    loadForm(id)

    return () => {
      //unmount
      dispatch(cleanFieldAutocompleteSearch(name))
    }
  }, [])

  //atualização de form
  useEffect(() => {
    //verificar se form data mudou
    if (formHasSubmitted(formStore, name)) {
      //envia para saga de criação de pessoas (cria usuario se for novo)
      submitForm(dispatch, formStore, name, upsertDoor)
    }
    //se o upsert mudar o formulario pra persister quer dizer que inseriu o dado ok
    if (formHasPersisted(formStore, name)) {
      //limpa estado do form - tentar colocar no unmount
      cleanForm(dispatch, name)
      history.push(backToRoute)
    }
    // autocomplete condominium
    if (getAutocompleteFieldValue(formStore, name, "condominiumId")) {
      updatedAutocompleteOptions(dispatch, formStore, name, "condominiumId", fetchAutocompleteCondominium)
    }

    if (getAutocompleteFieldValue(formStore, name, "cameraRef")) {
      updatedAutocompleteOptions(dispatch, formStore, name, "cameraRef", fetchAutocompleteCamera)
    }

    if (getAutocompleteFieldValue(formStore, name, "peoplesInDoor")) {
      //atualiza os campos do autocomplete de condominiumId
      updatedAutocompleteOptions(dispatch, formStore, name, "peoplesInDoor", fetchAutocompletePeopleInDoor)
    }

  }, getFormUseEffectArray(formStore, name, 
    [
      getAutocompleteFieldValue(formStore, name, "condominiumId"), 
      getAutocompleteFieldValue(formStore, name, "cameraRef"),
      getAutocompleteFieldValue(formStore, name, "peoplesInDoor")
    ]
  ))

  return (
    <Page title={title} backToRoute={backToRoute}>
      <FormContainer formTitle="Cadastro de Porta" name={name} />
    </Page>
  )
};

export default DoorForm;
