import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ADD_NOTIFICATION_MESSAGE, UPDATE_NOTIFICATION_MESSAGE } from '../../reducers/notification';
import './Notification.css';
import moment from 'moment';
import {
  useSnackbar
} from 'notistack';
import _ from 'lodash';
import { usePrevious } from '../UsePrevious/UsePrevious';

export const addNotication = (notification: any) => {
  const {
    message, typeNotification
  } = notification

  const date = moment().toString()

  return {
          type: ADD_NOTIFICATION_MESSAGE, 
          date,
          message, 
          typeNotification,
          visible: true
        }
}


export const updateNotication = (notification: any, index:number) => {
  return {type: UPDATE_NOTIFICATION_MESSAGE, 
          message: notification?.message, 
          typeNotification: notification?.typeNotification,
          date: notification?.date,
          visible: notification?.visible,
          index}
}

const Notification: React.FC = () => {
  const notificationStore = useSelector((state: any) => state.notificationState)
  const { enqueueSnackbar } = useSnackbar();

  //pega valor antigo
  const previousMessages = usePrevious(notificationStore.messages);
 
  useEffect(() => {
    if(previousMessages && notificationStore){
      const differenceArray:any = _.xor(previousMessages, notificationStore.messages)
      for(const message of differenceArray){
        enqueueSnackbar(message.message, {variant: message.typeNotification})
      }
    }
  },[notificationStore.messages])

  return (
      <>
      </>
  );
};

export default Notification;