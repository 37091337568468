export class LocalStorageService {

    getItem(key: string) {
        const localStorageItem = localStorage.getItem(key)
        if (localStorageItem) {
            return JSON.parse(localStorageItem)
        }

        return null;
    }

    setItem(key: string, payload: Object) {
        localStorage.setItem(key, JSON.stringify(payload))
    }

    clear() {
        localStorage.clear()
    }
}