import _ from 'lodash';
import { call, put } from 'redux-saga/effects'
import { updateFormField, updateFormFieldValue, updateFormState } from '../actions/form'
import { addNotication } from '../components/Notification/Notification';
import { ContainerService } from '../services/container';
import { UtilsService } from '../services/utils';

export const ACTION_UPSERT_DOOR = 'ACTION_UPSERT_DOOR'
export const ACTION_GET_DOOR = 'ACTION_GET_DOOR'
export const ACTION_ERROR_UPSERT_DOOR = 'ACTION_ERROR_UPSERT_DOOR'
export const ACTION_ERROR_GET_DOOR = 'ACTION_ERROR_GET_DOOR'

const containerService = new ContainerService().build()

const FORM_NAME = "door"

export function* upsertDoorFlow(action: any) {
    try {
        if (UtilsService.formDataNotEmpty(action.formData)) {
            const { door, door_people } = action.formData
            const doorPayload = {
                id: door.id,
                condominiumId: door.condominiumId.id,
                label: door.label,
                item: door.item,
                cameraRef: door.cameraRef?.id,
                type: door.type,
                type_share: door.type_share,
                private: door.private
            }

            let doorResponsePayload: any
            if (!doorPayload.id) {
                doorResponsePayload = yield call(
                    [
                        containerService.doorApiService,
                        containerService.doorApiService.createDoor
                    ],
                    _.omit(doorPayload, "id")
                )
            } else {
                doorResponsePayload = yield call(
                    [
                        containerService.doorApiService,
                        containerService.doorApiService.updateDoor
                    ],
                    doorPayload
                )
            }

            // ajustar private se tiver
            if(
                door_people && door_people.peoplesInDoor && 
                doorResponsePayload && doorResponsePayload.id
            ) {
                yield call(
                    [
                        containerService.doorApiService,
                        containerService.doorApiService.deleteAllPeoplesFromPrivateDoor
                    ],
                    doorResponsePayload.id
                )
                
                const peoplesIds = door_people.peoplesInDoor.map((p:any) => p.id)
                
                yield call([
                    containerService.doorApiService,
                    containerService.doorApiService.createPeoplesFromPrivateDoor
                ], 
                    doorResponsePayload.id,
                    peoplesIds
                )
            }

            yield put(updateFormField(FORM_NAME, {}, { isPersisted: true }))

            const notification = {
                message: "Porta salvo com sucesso",
                typeNotification: "success"
            }

            yield put(addNotication(notification))
        }

    } catch (error) {
        const notification = {
            message: error,
            typeNotification: "error"
        }

        yield put(addNotication(notification))

        //LIMPAR FORM
        yield put(updateFormState(FORM_NAME, { isPersisted: false, isSubmitted: false }))
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}

export function* getDoorsFlow(action: any): any {
    try {
        const id: number = action.id
        if (id === 0) return;

        yield put({ type: 'PERSISTING_API' })

        const door = yield call(
            [
                containerService.doorApiService,
                containerService.doorApiService.getDoor
            ],
            id
        )

        const doorPeople = yield call(
            [
                containerService.doorApiService,
                containerService.doorApiService.getPeoplesPrivateByDoorId
            ],
            id
        )

        const doorResponse = {
            ...door,
            condominiumId: {
                id: door.condominium.id,
                label: `${door.condominium.id} - ${door.condominium.name}`
            },
            item: door.item,
            cameraRef: { id: door.cameraRef, label: door.cameraRef },
            type: door.type,
            type_share: door.type_share,
            private: door.private,
            peoplesInDoor: doorPeople.map((d: any) => ({ id: d.peopleId, label: d.people.name }))
        }

        // carrega doors_people
        yield put(updateFormFieldValue(FORM_NAME, doorResponse, action.fields))

    } catch (error) {
        console.log('getDoorsFlow', error)
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}