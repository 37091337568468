import React, { useMemo, useState } from 'react';
import './Page.css';
import { IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { businessOutline, ellipsisHorizontal, logOutOutline, homeOutline, keyOutline, peopleOutline, phoneLandscapeOutline, keypadOutline} from 'ionicons/icons';
import { useDispatch, useSelector} from 'react-redux';
import { Fade, LinearProgress, Menu, MenuItem} from '@material-ui/core';
import { useHistory } from 'react-router';
import { ContainerService } from '../../services/container';
import RoutesConsts from "../../consts/RoutesConsts";
import { LOGOUT } from '../../reducers/login';
import Notification from '../../components/Notification/Notification'
import * as CONSTS from '../../services/const'

const cssSpacingBetweenIconAndTextMenu = {
    marginRight: "5px"
}

const containerService = new ContainerService().build()

const Page: React.FC<any> = (params: any) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const loadingStore = useSelector((state: any) => state.loadingState)

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    };

    const handleClose = (e?: any) => {
        setOpen(false)
    }

    const menus = [
        /*painel admin*/
        //adicionar modal criando usuario
        {name: 'Inicio', ico: homeOutline, path: RoutesConsts.MAIN, show: true},
        {name: 'Condominio', ico: businessOutline, path: RoutesConsts.CONDOMINIUM, show: true},
        {name: 'Portas', ico: keyOutline, path: RoutesConsts.CONDOMINIUM_DOOR, show: true},
        {name: 'Pessoas', ico: peopleOutline, path: RoutesConsts.PEOPLE, show: true},
        {name: 'Interfones', ico: keypadOutline, path: RoutesConsts.CONDOMINIUM_GATEKEEPERS, show: true},
        /*painel admin*/
        // {name: 'Pessoas Autorizadas', ico: peopleOutline, path: RoutesConsts.AUTHORIZED_PERSON, show: true},
        // {name: 'Acessos', ico:keyOutline, path: RoutesConsts.ACCESS, show: true},
        // {name: 'Convidados', ico:peopleCircleOutline, path: RoutesConsts.INVITEES, show: true},
        // {name: 'Agendamentos', ico:bookmarkOutline, path: RoutesConsts.SCHEDULE, show: true},
        // {name: 'Mural de Recados', ico:chatboxOutline, path: RoutesConsts.MESSAGE_DASHBOARD, show: true},
        // {name: 'Entregas', ico:cubeOutline, path: RoutesConsts.DELIVERY, show: true},
        // {name: 'Pedidos e Manifestações', ico:bookOutline, path: RoutesConsts.TICKETS, show: true},
        // {name: 'Acionamentos', ico:appsOutline, path: RoutesConsts.EVENTS, show: false},
        // {name: 'Meu Qr Code', ico:qrCodeOutline, path: RoutesConsts.MYQR, show: false},

        // {name: 'Emergência', ico:flashOutline, path: RoutesConsts.EMERGENCY, show: false},
    ]

    const MenuApp = useMemo(() => {
        const doLogoff = () => {
            const lastLoginEmail = containerService.localStorageService.getItem(CONSTS.LAST_LOGIN_EMAIL_LOCALSTORAGE_ITEM)

            containerService.localStorageService.clear()
            containerService.localStorageService.setItem(CONSTS.LAST_LOGIN_EMAIL_LOCALSTORAGE_ITEM, lastLoginEmail)

            dispatch({ type: LOGOUT })
            history.push(RoutesConsts.LOGIN)

            handleClose()
        }

        const doRoute = (route: string) => {
            history.push(route)
        }

        return <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
        >
            {menus
            .filter((menuItem:any) => menuItem.show)
            .map((menuItem:any) => {
                if(menuItem.show){
                    return <MenuItem key={menuItem.name} onClick={e => doRoute(menuItem.path)}> 
                        <IonIcon icon={menuItem.ico} style={cssSpacingBetweenIconAndTextMenu}/> {menuItem.name} 
                    </MenuItem>
                }
                return ""
            })}
            <MenuItem onClick={doLogoff}>
                <IonIcon icon={logOutOutline} style={cssSpacingBetweenIconAndTextMenu} /> Logout
            </MenuItem>
        </Menu>
    }, [menus, anchorEl, open])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="warning">
                    <IonButtons slot="start">
                        <IonButton onClick={handleClick}>
                            <IonIcon icon={ellipsisHorizontal} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{params.title}</IonTitle>
                    <IonButtons slot="end">
                        <IonBackButton defaultHref={params.backToRoute} />
                    </IonButtons>
                </IonToolbar>

                {MenuApp}
                {loadingStore.loading ? <LinearProgress /> : ""}
                {/* <LinearProgress color="secondary"/> */}
            </IonHeader>
            {params.tools}
            <IonContent
                scrollEvents={true}
                onIonScrollStart={() => { }}
                onIonScroll={() => { }}
                onIonScrollEnd={() => { }}>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">{params.title}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {params.children}
            </IonContent>
            <IonFooter>
                <Notification />
            </IonFooter>
        </IonPage>
    );
};

export default Page;
