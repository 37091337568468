import React, { useEffect, useState } from 'react';
import {
  IonApp
} from '@ionic/react';
import { Redirect, Route } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { IonReactHashRouter } from '@ionic/react-router';

import Main from './pages/main/Main';
import Login from "./pages/login/Login";

import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './theme/variables.css';
import RoutesConsts from "./consts/RoutesConsts";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import AuthorizedPerson from "./pages/authorized_person/AuthorizedPerson";
import FormAccess from "./pages/access_form/FormAccess";
import People from './pages/people/People';
import PeopleForm from './pages/people_form/PeopleForm';
import Access from './pages/access/Access';
import AuthorizedPersonForm from './pages/authorized_person_form/AuthorizedPersonForm';
import Schedule from './pages/schedule/Schedule';
import ScheduleForm from './pages/schedule_form/ScheduleForm';
import Condominium from './pages/condominium/Condominium';
import CondominiumForm from './pages/condominium_form/CondominiumForm';
import Delivery from './pages/delivery/Delivery';
import DeliveryForm from './pages/delivery_form/DeliveryForm';
import Ticket from './pages/ticket/Ticket';
import Door from './pages/door/Door';
import DoorForm from './pages/door_form/DoorForm';
import { ContainerService } from './services/container';
import { LOGGED_IN_STATUS, LOGIN_SUCCESS } from './reducers/login';
import CondominiumGateKeeper from './pages/condominium_gatekeeper/CondominiumGateKeeper';
import CondominiumGateKeeperForm from './pages/condominium_gatekeeper_form/CondominiumGateKeeperForm';

//se for electron ele usa o hash router #/app (like cordova)
// const Router:any = isPlatform("electron") ? IonReactHashRouter : IonReactRouter;
const Router: any = IonReactHashRouter;

const App: React.FC = () => {
  const loginStore = useSelector((state: any) => state.loginState)
  const [currentPath, setCurrentPath] = useState([] as any)
  const dispatch = useDispatch()

  useEffect(() => {
    const parsedCurrentPath = document.location.hash.substr(1)
    setCurrentPath(parsedCurrentPath)
  }, [loginStore.isAuth, document.location])

  useEffect(() => {
    const containerService = new ContainerService().build()

    if (containerService?.authService?.isAlreadyLogged() && !loginStore.isAuth) {
      dispatch({
        type: LOGIN_SUCCESS,
        status: LOGGED_IN_STATUS,
        isAuth: true,
        error: false
      })
    }
  })

  return (
    <IonApp>
      <Router>
        {loginStore.isAuth && currentPath === RoutesConsts.ROOT ? <Redirect to={RoutesConsts.MAIN} /> : null}
        {!loginStore.isAuth && currentPath === RoutesConsts.ROOT ? <Redirect to={RoutesConsts.LOGIN} /> : null}
        {loginStore.isAuth && currentPath === RoutesConsts.LOGIN ? <Redirect to={RoutesConsts.MAIN} /> : null}
        {!loginStore.isAuth && currentPath !== RoutesConsts.LOGIN ? <Redirect to={RoutesConsts.LOGIN} /> : null}

        <Route path={RoutesConsts.LOGIN} component={Login} exact={true} />
        <PrivateRoute path={RoutesConsts.MAIN} component={Main} exact={true} />
        <PrivateRoute path={RoutesConsts.ACCESS} component={Access} exact={true} />
        <PrivateRoute path={RoutesConsts.ACCESS_FORM} component={FormAccess} />
        <PrivateRoute path={RoutesConsts.PEOPLE} component={People} exact={true} />
        <PrivateRoute path={`${RoutesConsts.PEOPLE_FORM}/:id`} component={PeopleForm} />
        <PrivateRoute path={RoutesConsts.AUTHORIZED_PERSON} component={AuthorizedPerson} exact={true} />
        <PrivateRoute path={`${RoutesConsts.AUTHORIZED_PERSON_FORM}/:id`} component={AuthorizedPersonForm} />
        <PrivateRoute path={RoutesConsts.CONDOMINIUM} component={Condominium} exact={true} />
        <PrivateRoute path={`${RoutesConsts.CONDOMINIUM_FORM}/:id`} component={CondominiumForm} />
        <PrivateRoute path={RoutesConsts.SCHEDULE} component={Schedule} />
        <PrivateRoute path={`${RoutesConsts.SCHEDULE_FORM}/:id`} component={ScheduleForm} />
        <PrivateRoute path={RoutesConsts.DELIVERY} component={Delivery} />
        <PrivateRoute path={`${RoutesConsts.DELIVERY_FORM}/:id`} component={DeliveryForm} />
        <PrivateRoute path={RoutesConsts.TICKETS} component={Ticket} />
        <PrivateRoute path={RoutesConsts.CONDOMINIUM_DOOR} component={Door} />
        <PrivateRoute path={`${RoutesConsts.CONDOMINIUM_DOOR_FORM}/:id`} component={DoorForm} />
        <PrivateRoute path={RoutesConsts.CONDOMINIUM_GATEKEEPERS} component={CondominiumGateKeeper} />
        <PrivateRoute path={`${RoutesConsts.CONDOMINIUM_GATEKEEPERS_FORM}/:id`} component={CondominiumGateKeeperForm} />
      </Router>
    </IonApp>
  );
}

export default App;
