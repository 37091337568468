import React from 'react';
import './Ticket.css';
import { FormatDate } from "../../components/DataTable/DataTableContainer";
import RoutesConsts from "../../consts/RoutesConsts";
import { ContainerService } from '../../services/container';
import ApiDataTable from '../../components/ApiDataTable/ApiDataTable'
import Page from '../../components/Page/Page';

const containerService = new ContainerService().build()

const Ticket: React.FC = () => {

  const columns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Condominio',
      sortable: true,
      cell: (row: any) => row?.condomium?.name || "Não ha condominio para esse caso",
    },
    {
      name: 'Quem abriu',
      sortable: true,
      cell: (row: any) => row?.people?.name || "Não ha pessoas para esse caso",
    },
    {
      name: 'Tipo',
      sortable: true,
      cell: (row: any) => row?.type || "Sem tipo",
    },
    {
      name: 'Topico',
      selector: 'name',
      sortable: true,
      cell: (row: any) => row?.message || "Sem topico",
    },
    {
      name: 'Tipo',
      sortable: true,
      cell: (row: any) => row?.type || "Sem tipo",
    },
    {
      name: 'Criado em',
      selector: 'createdAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.createdAt),
    },
    {
      name: 'Atualizado em',
      selector: 'updatedAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.updatedAt),
    }
  ];

  const include: any = [{ relation: 'people' }]

  return (
    <Page
      title={'Tickets'}
      backToRoute={RoutesConsts.MAIN}
    >
      <ApiDataTable
        include={include}
        routeCreate={RoutesConsts.TICKETS_FORM}
        countService={(params: any) => containerService.ticketApiService.getTopicsCount(params)}
        getService={(filter: any) => containerService.ticketApiService.getTopics(filter)}
        deleteRow={(filter: any) => containerService.ticketApiService.deleteTicket(filter)}
        columns={columns} />
    </Page>
  );
};

export default Ticket;