import React from 'react';
import './CondominiumGateKeeper.css';
import { FormatDate } from "../../components/DataTable/DataTableContainer";
import RoutesConsts from "../../consts/RoutesConsts";
import { ContainerService } from '../../services/container';
import ApiDataTable from '../../components/ApiDataTable/ApiDataTable'
import Page from '../../components/Page/Page';
import { IonCard, IonCardContent, IonCol, IonGrid } from '@ionic/react';

const containerService = new ContainerService().build()

const CondominiumGateKeeper: React.FC = () => {

  const columns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Condominio',
      selector: 'condominiun',
      sortable: true,
      cell: (row: any) => row?.condominium?.name || "Sem condominio",
    },
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
      cell: (row: any) => row?.name,
    },
    {
      name: 'Porta',
      selector: 'condominiumDoor.name',
      sortable: true,
      cell: (row: any) => row?.condominiumDoor?.label || "Sem porta"
    },
    {
      name: 'Interfone',
      selector: 'extension',
      sortable: true,
    },
    {
      name: 'Criado em',
      selector: 'createdAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.createdAt),
    },
    {
      name: 'Atualizado em',
      selector: 'updatedAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.updatedAt),
    }
  ];
  const include: any = [{ relation: 'condominium' }, { relation: 'condominiumDoor' }]
  return (
    <Page title={'Interfones'} backToRoute={RoutesConsts.MAIN}>
      <IonGrid fixed={true} className="ion-flex ion-flex-column ion-justify-content-center" style={{ height: '100%' }}>
        <IonCol>
          <IonCard>
            <IonCardContent>
              <ApiDataTable
                textNew='Novo Interfone'
                include={include}
                routeCreate={RoutesConsts.CONDOMINIUM_GATEKEEPERS_FORM}
                countService={(params: any) => containerService.condominiumGateKeeperApiService.getCondominiumGateKeeperCount(params)}
                getService={(filter: any) => containerService.condominiumGateKeeperApiService.getCondominiumGateKeepers(filter)}
                deleteRow={(filter: any) => containerService.condominiumGateKeeperApiService.deleteCondominiumGateKeeper(filter)}
                columns={columns}
              />
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonGrid>
    </Page>
  );
};

export default CondominiumGateKeeper;