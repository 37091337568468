import React, {useEffect} from 'react';
import './FormAccess.css';
import RoutesConsts from "../../consts/RoutesConsts";
import FormContainer from "../../components/FormContainer/FormContainer";
import {
    TYPE_DATEPICKER,
    TYPE_ID,
    TYPE_RADIO,
    TYPE_SELECT,
    TYPE_TEXT,
    TYPE_TOGGLE
} from "../../components/FormContainer/Field"
import {useDispatch, useSelector} from "react-redux";
import {UPDATE_FORM} from "../../reducers/form";
import * as Yup from "yup";
import Page from '../../components/Page/Page';

const FormAccess: React.FC = () => {
    const dispatch = useDispatch()
    const formStore = useSelector( (state: any) => state.formState )

    const fields = {"id":{type:TYPE_ID, label:"ID", value:1, validation: Yup.number().required()},
              "name":{type:TYPE_TEXT, label:"Nome", value:"text", validation: Yup.string().required()},
              "obs":{type:TYPE_TEXT, label:"Obs", value:"obs", validation: Yup.string().required()},
              "item":{type:TYPE_TEXT, label:"Item", value:"1", validation:null},
              "test":{type:TYPE_SELECT, label:"Teste", value:"1", options: [{label:"teste 1", value:"1"}, {label:"teste 2", value:"2"}], validation:null},
              "test2":{type:TYPE_RADIO, label:"Teste2", value:"1", options: [{label:"teste 1", value:"1"}, {label:"teste 2", value:"2"}], validation:null},
              "datepicker":{type:TYPE_DATEPICKER, label:"Datepicker", value:"text", format: "MM/DD/YYYY", min:2000, max:2025, validation: Yup.string().required()},
              "toggle":{type:TYPE_TOGGLE, label:"Sim/Nao", value: true, validation: null},
        }

    useEffect(() => {
        dispatch({type:UPDATE_FORM, fields, formData:{}, isSubmitted: false, isReadOnly: false})
    },[])


    useEffect(() => {
        if(formStore.isSubmitted){
            console.log('formStore.isSubmitting:', formStore.isSubmitted)
            console.log('formStore.formData:', formStore.formData)
        }
    },[formStore.isSubmitted])

  return (
    <Page 
    title={'Acessos'}
    backToRoute={RoutesConsts.ACCESS}
    >
      <FormContainer name={'people'} />
    </Page>
  )
};

export default FormAccess;
