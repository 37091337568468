import * as CONSTS from "../const"
import * as Axios from "axios"
import { LocalStorageService } from "../local-storage"
import { LOGOUT } from "../../reducers/login"
import { useDispatch } from "react-redux"
import { UtilsService } from "../utils"

export interface HttpService {
    post(url: string, payload: any, headers: any): Promise<any>
    get(url: string, headers: any): Promise<any>
    delete(url: string, headers: any): Promise<any>
    put(url: string, payload: any, headers: any): Promise<any>
    getHeader(): any
}

export class AxiosHttpService implements HttpService {
    httpService: any

    constructor(
        private localStorageService: LocalStorageService
    ) {
        this.httpService = Axios.default.create()
        this.registerInterceptors()
    }

    registerInterceptors() {
        this.httpService.interceptors.response.use((response: any) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, (error: any) => {
            if (error?.response?.status === 401 && !UtilsService.isLoginPage()) {
                this.localStorageService.clear()
                UtilsService.reloadPage()
            }
            return Promise.reject(error);
        });

    }

    put(url: string, payload: any, headers: any): Promise<any> {
        return this.httpService.put(url, payload, headers)
    }

    delete(url: string, headers: any): Promise<any> {
        return this.httpService.delete(url, headers)
    }

    post(url: string, payload: any, headers: any): Promise<any> {
        return this.httpService.post(url, payload, headers)
    }

    get(url: string, headers: any): Promise<any> {
        return this.httpService.get(url, headers)
    }

    getHeader(): Axios.AxiosRequestConfig {
        const authToken = this.localStorageService.getItem(CONSTS.AUTHORIZED_USER_LOCALSTORAGE_TOKEN);
        const authorization = authToken ? `Bearer ${authToken}` : ''

        return {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorization
            }
        }
    }

}