//COLOCAR NO USE EFFECTS

import { updateFormState } from "../../actions/form";
import { UPDATE_AUTOCOMPLETE_FIELD } from "../../reducers/form";

//VERIFICA SE FORM FOI ALTERADO
export const formHasSubmitted = (formStore:any, formName:any) => {
    if(formStore && formStore[formName] && formStore[formName]?.isSubmitted){
        return true;
    }
    return false;
}

//VERIFICA SE FORM FOI ATUALIZADO
export const formHasPersisted = (formStore:any, formName:any) => {
    
    if(formStore && formStore[formName] && formStore[formName]?.isSubmitted){
        return true;
    }
    return false;
}

export const getAutocompleteName = (formName:string, fieldName:string) => `SearchOn_${formName}_${fieldName}`

export const getAutocompleteFieldValue = (formStore:any, formName:any, fieldName:any) => {
    return formStore[formName] ? formStore[formName][`SearchOn_${formName}_${fieldName}`] : null
}

export const updatedAutocompleteOptions = async (dispatch:any, formStore:any, formName:any, fieldName:any, fetchFunction:any) => {
    //pega valor que foi digitado no autocomplete
    const autoCompleteFieldValue = getAutocompleteFieldValue(formStore, formName, fieldName)
    const responseFetchFunction = await fetchFunction(autoCompleteFieldValue)
    
    // dispatch({type:UPDATE_FORM, 
    //     name:formName, 
    //     fields: {
    //         ...formStore[formName].fields,
    //         [fieldName]: {
    //             ...formStore[formName].fields[fieldName],
    //             options: //[...formStore[formName].fields[fieldName].options, ...responseFetchFunction]
    //             [...responseFetchFunction]
    //         }
    //     }
    // })
    dispatch({type: UPDATE_AUTOCOMPLETE_FIELD, 
        name: formName, 
        field: {
            name: fieldName,
            options:  [...responseFetchFunction]
        }
    })
    
}



//RETORNA OS VALORES PARA USEEFFECT DO FORM
export const getFormUseEffectArray = (formStore:any, formName:any, aditiontal:any) => 
    [formStore[formName]?.isSubmitted, 
     formStore[formName]?.isPersisted,
     ...aditiontal]
//aditional -> autocomplete formStore[name]?.SearchOn_authorized_people_form_condominiumId

//DISPACHA OS DADOS DO FORMULARIO USANDO FUNCA DO REDUX
export const submitForm = (dispatch:any, formStore:any, formName:any, upsertFunction:any) => {
    dispatch(upsertFunction(formStore[formName]?.formData, formStore[formName]?.fields))
}

//LIMPA FORM
export const cleanForm = (dispatch:any, formName:any) => {
    dispatch(updateFormState(formName, {isPersisted: false, isSubmitted: false, formData: {} }))

}