import React, {useEffect} from 'react';
import './CondominiumGateKeeperForm.css';
import RoutesConsts from "../../consts/RoutesConsts";
import FormContainer from "../../components/FormContainer/FormContainer";
import {
  TYPE_AUTO_COMPLETE,
  TYPE_ID,
  TYPE_TEXT,
} from "../../components/FormContainer/Field"

import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Page from '../../components/Page/Page';
import { getCondominiumGateKeeper, upsertCondominiumGateKeeper } from '../../actions';
import { cleanFieldAutocompleteSearch, createFormField } from '../../actions/form';
import { useHistory } from 'react-router';
import { ContainerService } from '../../services/container';
import { cleanForm, formHasPersisted, formHasSubmitted, getAutocompleteFieldValue, getFormUseEffectArray, submitForm, updatedAutocompleteOptions } from '../../components/FormContainer/FormEffects';

const CondominiumGateKeeperForm: React.FC = (params: any) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const formStore = useSelector((state: any) => state.formState)

  const id: number = parseInt(params.match.params.id)
  const name = 'condominium_gatekeeper'
  const backToRoute = RoutesConsts.CONDOMINIUM_GATEKEEPERS
  const title = 'Interfones'

  const containerService = new ContainerService().build()

  const isEdit = () => {
    return id && id !== 0
  }

  //colocar em actions/index.tsx
  const loadForm = (id: number) => {
    const fields = {
      "id": { type: TYPE_ID, name: "id", row: 1, group: "condominium_gatekeeper", label: "Cod. Porteiro", offset: 0, value: 0, validation: null },
      "name": { type: TYPE_TEXT, name: "name", row: 1, group: "condominium_gatekeeper", label: "Nome", value: "", validation: Yup.string().required('Informe o nome') },
      "extension": { type: TYPE_TEXT, name: "extension", row: 1, group: "condominium_gatekeeper", label: "Interfone", value: "", validation: null },
      "condominiumId": { type: TYPE_AUTO_COMPLETE, name: "condominiumId", updateValue: true, row: 2, offset: 0, group: "condominium_gatekeeper", options: [], async: true, multiple: false, label: "Condominio", value: null, validation: Yup.object().nullable().required('Selecione algum condomium') },
      "door": { type: TYPE_AUTO_COMPLETE, name: "door", row: 5, offset: 1, group: "condominium_gatekeeper", options: [], async: true, multiple: false, label: "Porta", value: [], validation: null},
      "extension_sip": { type: TYPE_TEXT, name: "extension_sip", row: 6, readOnly: true, group: "sip", label: "Ramal", value: "", validation: null },
      "secret_sip": { type: TYPE_TEXT, name: "secret_sip", row: 6, readOnly: true, group: "sip", label: "Senha", value: "", validation: null },
    }

    //carrega form no redux - se nao colocar nao será renderizado pelo componente
    dispatch(createFormField(name, fields))

    if (isEdit()) {
      //altera campos de acordo com a edição
      console.log("Carregando dados do id", id)
      dispatch(getCondominiumGateKeeper(id, fields))
    }

  }

  const fetchAutocompleteCondominium = async (condominiumValue: any) => {
    const filter = {
      filter: {
        where: {
          name: { like: `${condominiumValue}%` }
        }
      }
    }

    const condominiums = await containerService.condominiumApiService.getCondominiums(filter)
    if (condominiums.data) {
      return condominiums.data.reduce((accCondominium: any, condominium: any) =>
        [...accCondominium, { id: condominium.id, label: `${condominium.id} - ${condominium.name}` }], [])
    }
    return []
  }

  const fetchAutocompleteDoor = async (doorValue: any) => {
    const selectedCondominium = formStore.condominium_gatekeeper?.fields?.condominiumId?.value
    const condominiumId = selectedCondominium?.id

    const filter = {
      filter: {
        where: {
          condominiumId: {
            eq: condominiumId
          },
          deletedAt: {eq: null}
        }
      }
    }

    const doors = await containerService.doorApiService.getDoors(filter)
    if(doors.data) {
      return doors.data.reduce((accDoor: any, door: any) =>
        [...accDoor, { id: door.id, label: `${door.id} - ${door.label}` }], [])
    }
    return []
  }

  //init
  useEffect(() => {
    //Carrega dados do formulario para redux
    //para cada form é necessario um dispatch para criar os dados no redux
    loadForm(id)

    return () => {
      //unmount
      dispatch(cleanFieldAutocompleteSearch(name))
    }
  }, [])

  //atualização de form
  useEffect(() => {
    //verificar se form data mudou
    if (formHasSubmitted(formStore, name)) {
      //envia para saga de criação de pessoas (cria usuario se for novo)
      submitForm(dispatch, formStore, name, upsertCondominiumGateKeeper)
    }

    //se o upsert mudar o formulario pra persister quer dizer que inseriu o dado ok
    if (formHasPersisted(formStore, name)) {
      //limpa estado do form - tentar colocar no unmount
      cleanForm(dispatch, name)
      history.push(backToRoute)
    }

    if (getAutocompleteFieldValue(formStore, name, "condominiumId")) {
      //atualiza os campos do autocomplete de condominiumId
      updatedAutocompleteOptions(dispatch, formStore, name, "condominiumId", fetchAutocompleteCondominium)
    }

    if (getAutocompleteFieldValue(formStore, name, "door")) {
      //atualiza os campos do autocomplete de door
      updatedAutocompleteOptions(dispatch, formStore, name, "door", fetchAutocompleteDoor)
    }

    //ADICIONA O PERSIST E O SUBMITED E CAMPOS ADICIONAIS PARA AUTOCOMPLETE
  }, getFormUseEffectArray(formStore, name,
    [
      getAutocompleteFieldValue(formStore, name, "condominiumId"),
      getAutocompleteFieldValue(formStore, name, "door")
    ]
  ))

  return (
    <Page title={title} backToRoute={backToRoute}>
      <FormContainer name={name} />
    </Page>
  )
};

export default CondominiumGateKeeperForm;
