import React, { useEffect, useState } from 'react';
import {
    IonButton,
    IonContent,
    IonItem,
    IonPage,
    IonLabel,
    IonToast,
    IonAvatar, IonInput, IonGrid, IonRow, IonCol, IonCard, IonCardContent
} from '@ionic/react';

import './Login.css';
import UILabelConsts from "../../consts/UILabelConsts";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_REQUEST } from "../../reducers/login";
import { exitOutline } from "ionicons/icons";
import { ContainerService } from '../../services/container'
import * as CONSTS from '../../services/const'

const containerService = new ContainerService().build()

const Login: React.FC = () => {
    const dispatch = useDispatch()
    const loginStore = useSelector((state: any) => state.loginState)

    const [toastOpen, setToastOpen] = useState(false)
    const [email, setEmail] = useState(containerService.localStorageService.getItem(CONSTS.LAST_LOGIN_EMAIL_LOCALSTORAGE_ITEM) || "" as any)
    const [password, setPassword] = useState("" as any)
    const doLogin = () => dispatch({ type: LOGIN_REQUEST, email, password })

    useEffect(() => {
        if (loginStore.error && !toastOpen) {
            setToastOpen(true)
        }
    }, [loginStore])

    return (
        <IonPage id="login-container">
            <IonContent scrollEvents={false} fullscreen className="ion-padding ion-text-center">
                <IonGrid fixed={true} className="ion-flex ion-flex-column ion-justify-content-center" style={{ height: '100%' }}>
                    <IonCard>
                        <IonCardContent>
                            <IonRow>
                                <IonCol className="avatarLogoCol">
                                    <IonAvatar>
                                        <img src="assets/logo2.png" alt="Logo" />
                                    </IonAvatar>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel color="dark" position="floating"> Email</IonLabel>
                                        <IonInput
                                            type="email"
                                            value={email}
                                            onBlur={e => { setEmail(e.target.value) }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel color="dark" position="floating"> Senha</IonLabel>
                                        <IonInput
                                            type="password"
                                            value={password}
                                            onBlur={e => { setPassword(e.target.value) }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>


                            <IonRow>
                                <IonCol>
                                    <IonButton expand="block" color="warning" onClick={e => doLogin()}> ENTRAR </IonButton>

                                    <p style={{ fontSize: "medium", marginTop: 15 }}>
                                        Não possui uma conta? <a href="#">Solicitar acesso!</a>
                                    </p>
                                </IonCol>
                            </IonRow>

                            <IonToast
                                isOpen={toastOpen}
                                message={loginStore.status}
                                position="bottom"
                                color={"dark"}
                                buttons={[
                                    {
                                        text: UILabelConsts.CLOSE_BUTTON,
                                        role: 'cancel',
                                        handler: () => {
                                            setToastOpen(false)
                                        }
                                    }
                                ]}
                            />
                        </IonCardContent>
                    </IonCard>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Login;