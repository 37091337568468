import * as CONSTS from "../const"
import { ApiService } from "./api"

export class ScheduleApiService extends ApiService {
    async getSchedule(id: any): Promise<any> {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_SCHEDULE}/${id}`
            const params: any = { filter: { include: [{ relation: 'people' }] } }
            const headers = { ...this.httpService.getHeader(), params }
            const scheduleGetResponse: any = await this.httpService.get(url, headers)

            if (!scheduleGetResponse.data) {
                throw new Error("Error ao carregar agendamento")
            }

            return scheduleGetResponse.data
        } catch (error) {
            return error
        }
    }

    async createSchedule(schedule: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_SCHEDULE}`
            const headers = { ...this.httpService.getHeader() }
            const scheduleResponse: any = await this.httpService.post(url, schedule, headers)

            if (!scheduleResponse.data) {
                throw new Error("Error ao criar agendamento")
            }

            return scheduleResponse.data
        } catch (error) {
            return error
        }
    }

    async updateSchedule(schedule: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_SCHEDULE}/${schedule.id}`
            const headers = { ...this.httpService.getHeader() }
            const scheduleResponse: any = await this.httpService.put(url, schedule, headers)

            if (!scheduleResponse.data) {
                throw new Error("Error ao criar agendamento")
            }

            return scheduleResponse.data
        } catch (error) {
            return error
        }
    }

    async getSchedules(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_SCHEDULE}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async getSchedulesCount(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_SCHEDULE_COUNT}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async deleteSchedule(id: number) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_SCHEDULE}/${id}`
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.delete(url, headers)

        return response
    }
}