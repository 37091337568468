import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import Store  from './store';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack'; 
import { Button } from '@material-ui/core';
// console.log('Enviroment: ',process.env.NODE_ENV, process.env.NODE_HOST);

//rastreio de gargalos praticas
// if (process.env.NODE_ENV === 'development') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render');
//     whyDidYouRender(React, {
//         trackAllPureComponents: true,
//     });
// }
const notistackRef:any = React.createRef();
const onClickDismiss = (key:any) => () => { 
    notistackRef?.current?.closeSnackbar(key);
}

ReactDOM.render(
    <Provider store={Store}>
        <SnackbarProvider 
            maxSnack={15} 
            hideIconVariant={false}
            ref={notistackRef}
            action={(key) => (
                <Button onClick={onClickDismiss(key)}>
                    x
                </Button>
            )}
        >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App />
            </MuiPickersUtilsProvider>
        </SnackbarProvider>
    </Provider>,
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
