import * as CONSTS from "../const"
import { ApiService } from "./api"

export class PeopleApiService extends ApiService {
    async getPeoples(params: any) {
        const search = params.search ? params.search.value : '';

        const url = `${CONSTS.API_URL}${CONSTS.API_URL_PEOPLE}/new?search=${search}`

        if(params.search) {
            delete params.search;
        }

        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async getPeoplesByCondominium(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_PEOPLE_BY_CONDOMINIUM}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async getPeoplesCount(params: any) {
        let search = '';
        if(params.where.and) {
            try {
                const [deletedAt, orClause] = params.where.and;
                const [orCondominiums] = orClause.or;
                search = orCondominiums.condominiums.like.replaceAll('%', '') || '';
            } catch(error) {
                console.warn('invalid search', error);
                search = '';
            }
        }

        const url = `${CONSTS.API_URL}${CONSTS.API_URL_PEOPLE_COUNT}/new?search=${search}`

        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async deletePeoples(id: number) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_PEOPLE}/${id}`
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.delete(url, headers)

        return response
    }

    async getCondominiumsWhereILive(id: any, params: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_PEOPLE}/${id}${CONSTS.API_URL_CONDOMINIUM_WHEREILIVE}`
            const headers = { ...this.httpService.getHeader(), params }
            const responseCondominiumWhereIlive: any = await this.httpService.get(url, headers)

            if (!responseCondominiumWhereIlive.data) {
                throw new Error("Error ao carregar condominios do usuario")
            }

            return responseCondominiumWhereIlive.data
        } catch (error) {
            return error
        }
    }

    async getPeople(id: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_PEOPLE}/${id}`
            const params: any = { filter: { include: [{ relation: 'user' }] } }
            const headers = { ...this.httpService.getHeader(), params }

            const peopleGetResponse: any = await this.httpService.get(url, headers)

            if (!peopleGetResponse.data) {
                throw new Error("Error ao carregar people")
            }

            return peopleGetResponse.data
        } catch (error) {
            return error
        }
    }

    async updateUserPassword(userId: any, newPassword: any) {

        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_USER_CHANGE_PASSWORD}`
            const payload = { newPassword, userId }
            const headers = { ...this.httpService.getHeader() }
            const updatedUserPassword: any = await this.httpService.post(url, payload, headers)

            if (!updatedUserPassword.data) {
                throw new Error("Error ao atualizar senha do usuario")
            }

            return updatedUserPassword.data

        } catch (error) {
            return error
        }
    }

    async admin(userId: any, roles: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_USER_ADMIN}`
            const payload = { userId, roles }
            const headers = { ...this.httpService.getHeader() }
            const adminUser: any = await this.httpService.put(url, payload, headers)

            if (!adminUser.data) {
                throw new Error("Error ao atualizar role do usuario")
            }

            return adminUser.data
        } catch (error) {
            return error
        }
    }

    async createCondominiumsWhereILive(id: any, condominiumIds: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_PEOPLE}/${id}${CONSTS.API_URL_CONDOMINIUM_WHEREILIVE}`
            const headers = { ...this.httpService.getHeader() }
            const responseCreateCondominiumWhereIlive: any = await this.httpService.post(url, { condominiumIds }, headers)

            if (!responseCreateCondominiumWhereIlive.data) {
                throw new Error('Não foi possivel carregar condominios do suaurio')
            }

            return responseCreateCondominiumWhereIlive.data
        } catch (error) {
            return error
        }
    }

    async createUser(people: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_USER_SIGNIN}`
            const headers = { ...this.httpService.getHeader() }
            const userSignupResponse: any = await this.httpService.post(url, people, headers)

            if (!userSignupResponse.data) {
                throw new Error("Error ao criar usuario")
            }

            return userSignupResponse.data
        } catch (error) {
            return error
        }
    }

    async createPeople(people: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_PEOPLE}`
            const headers = { ...this.httpService.getHeader() }
            const peopleSignupResponse: any = await this.httpService.post(url, people, headers)

            if (!peopleSignupResponse.data) {
                throw new Error("Error ao criar pessoa")
            }

            return peopleSignupResponse.data
        } catch (error) {
            return error
        }
    }

    async updatePeople(people: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_PEOPLE}/${people.id}`
            const headers = { ...this.httpService.getHeader() }
            const peopleUpdateResponse: any = await this.httpService.put(url, people, headers)

            if (!peopleUpdateResponse.data) {
                throw new Error("Error ao editar people")
            }

            return peopleUpdateResponse.data
        } catch (error) {
            return error
        }
    }
}