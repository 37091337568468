import _ from 'lodash';
import { call, put } from 'redux-saga/effects'
import { updateFormField, updateFormFieldValue, updateFormState } from '../actions/form'
import { addNotication } from '../components/Notification/Notification';
import { ContainerService } from '../services/container';
import { UtilsService } from '../services/utils';

export const ACTION_UPSERT_SCHEDULE = 'ACTION_UPSERT_SCHEDULE'
export const ACTION_GET_SCHEDULE = 'ACTION_GET_SCHEDULE'
export const ACTION_ERROR_UPSERT_SCHEDULE = 'ACTION_ERROR_UPSERT_SCHEDULE'
export const ACTION_ERROR_GET_SCHEDULE = 'ACTION_ERROR_GET_SCHEDULE'

const containerService = new ContainerService().build()

const FORM_NAME = "schedule"

export function* upsertScheduleFlow(action: any) {
    try {
        if (UtilsService.formDataNotEmpty(action.formData)) {
            const { schedule } = action.formData //nao esta vindo o user id
            const schedulePayload = {
                id: schedule.id,
                peopleId: schedule.peopleId.id,
                scheduleStartAt: schedule.scheduleStartAt,
                scheduleEndAt: schedule.scheduleEndAt,
                description: schedule.description
            }
            if (!schedulePayload.id) {
                yield call(
                    [
                        containerService.scheduleApiService,
                        containerService.scheduleApiService.createSchedule
                    ],
                    _.omit(schedulePayload, "id")
                )
            } else {
                yield call(
                    [
                        containerService.scheduleApiService,
                        containerService.scheduleApiService.updateSchedule
                    ],
                    schedulePayload
                )
            }

            yield put(updateFormField(FORM_NAME, {}, { isPersisted: true }))

            const notification = {
                message: "Agendamento salvo com sucesso",
                typeNotification: "success"
            }
            yield put(addNotication(notification))
        }
        // redirect quando edita ou salva
    } catch (error) {
        console.log('upsertScheduleFlow', error)

        const notification = {
            message: error,
            typeNotification: "error"
        }

        yield put(addNotication(notification))

        //LIMPAR FORM
        yield put(updateFormState(FORM_NAME, { isPersisted: false, isSubmitted: false }))
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}

export function* getSchedulesFlow(action: any): any {
    try {
        const id: number = action.id
        if (id === 0) return;
        yield put({ type: 'PERSISTING_API' })

        const schedule: any = yield call(
            [
                containerService.scheduleApiService,
                containerService.scheduleApiService.getSchedule
            ],
            id
        )

        const scheduleResponse = {
            ...schedule,
            peopleId: {
                id: schedule.people.id,
                label: `${schedule.people.id} - ${schedule.people.name}`
            },
        }

        yield put(updateFormFieldValue(FORM_NAME, scheduleResponse, action.fields))

    } catch (error) {
        console.log('getSchedulesFlow', error)
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}