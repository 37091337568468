import React, { useEffect } from 'react'
import './CondominiumForm.css'
import RoutesConsts from "../../consts/RoutesConsts"
import FormContainer from "../../components/FormContainer/FormContainer"
import {
  TYPE_AUTO_COMPLETE,
  TYPE_ID,
  TYPE_SELECT,
  TYPE_TEXT,
} from "../../components/FormContainer/Field"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import Page from '../../components/Page/Page'
//import _ from 'lodash';
import { getCondominium, upsertCondominium } from '../../actions'
import { cleanFieldAutocompleteSearch, createFormField } from '../../actions/form'
import { useHistory } from 'react-router'
import { ApiService } from '../../services/api/api'
import { cleanForm, formHasPersisted, formHasSubmitted, getAutocompleteFieldValue, getFormUseEffectArray, submitForm, updatedAutocompleteOptions } from '../../components/FormContainer/FormEffects'
import { ContainerService } from '../../services/container'

const containerService = new ContainerService().build()

const CondominiumForm: React.FC = (params: any) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const formStore = useSelector((state: any) => state.formState)

  const id: number = parseInt(params.match.params.id)
  const name = 'condominium'
  const backToRoute = RoutesConsts.CONDOMINIUM
  const title = 'Condominio'

  const isEdit = () => {
    return id && id !== 0
  }

  //colocar em actions/index.tsx
  const loadForm = (id: number) => {
    const fields = {
      "id": { type: TYPE_ID, name: "id", row: 1, group: "condominium", label: "Cod. Pessoa", offset: 0, value: 0, validation: null },
      "name": { type: TYPE_TEXT, name: "name", row: 1, group: "condominium", label: "Nome", value: "", validation: Yup.string().required('Informe um nome') },
      "manager": { type: TYPE_AUTO_COMPLETE, name: "manager", row: 2, offset: 0, group: "condominium", options: [], async: true, multiple: false, label: "Sindico", value: null, validation: null /*Yup.object().nullable().required('Selecione alguma pessoa')*/ },
      "realState": { type: TYPE_TEXT, name: "realState", row: 2, group: "condominium", label: "Imobiliaria", value: "", validation: null },
      "peoplesInCondominium": { type: TYPE_AUTO_COMPLETE, name: "peoplesInCondominium", row: 3, offset: 1, group: "condominium", options: [], async: true, multiple: true, label: "Residentes", value: [], validation: null/*Yup.array().required('Selecione algum condominio')*/ },
      "address": { type: TYPE_TEXT, name: "address", row: 4, group: "condominium", label: "Endereço", value: "", validation: null },
      "addressNumber": { type: TYPE_TEXT, name: "addressNumber", row: 4, group: "condominium", label: "Numero Endereço", value: "", validation: null },
      "addressState": { type: TYPE_TEXT, name: "addressState", row: 5, group: "condominium", offset: 1, label: "Estado", value: "", validation: null },
      "cameraScreenView": { type: TYPE_TEXT, name: "cameraScreenView", row: 6, group: "condominium", offset: 1, label: "ScreenView (Digifort)", value: "", validation: null },
      "cameraUser": { type: TYPE_TEXT, name: "cameraUser", row: 7, group: "condominium", offset: 0, label: "Usuario (Digifort)", value: "", validation: null },
      "cameraPassword": { type: TYPE_TEXT, name: "cameraPassword", row: 7, group: "condominium", offset: 0, label: "Password (Digifort)", value: "", validation: null },
      "doorApiClientId": { type: TYPE_TEXT, name: "doorApiClientId", row: 8, group: "condominium", offset: 1, label: "ClientId (Portas)", value: "", validation: null },
      "phone": {type:TYPE_SELECT, name: "phone", row:9, offset:1, group: "condominium", label:"Usa Telefonia?", value: false, options: [{label:"Sim", value:'Y'}, {label:"Não", value:'N'}], validation: Yup.string().required()},
      "useAnalogExten": {type:TYPE_SELECT, name: "useAnalogExten", row:9, offset:1, group: "condominium", label:"Usa Telefonia Analógica?", value: false, options: [{label:"Sim", value:'Y'}, {label:"Não", value:'N'}], validation: Yup.string().required()},
    }

    //carrega form no redux - se nao colocar nao será renderizado pelo componente
    dispatch(createFormField(name, fields))

    if (isEdit()) {
      //altera campos de acordo com a edição
      console.log("Carregando dados do id", id)
      dispatch(getCondominium(id, fields))
    }

  }

  const fetchAutocompletePeople = async (peopleValue: any) => {
    const filter = {
      filter: {
        where: {
          name: { like: `${peopleValue}%` },
          deletedAt: {
            eq: null
          }
        }
      }
    }

    const people: any = await containerService.peopleApiService.getPeoples(filter)
    if (people.data) {
      return people.data.reduce((accPeople: any, people: any) =>
        [...accPeople, { id: people.id, label: `${people.id} - ${people.name}` }], [])
    }
    return []
  }

  //init
  useEffect(() => {
    //Carrega dados do formulario para redux
    //para cada form é necessario um dispatch para criar os dados no redux
    loadForm(id)

    return () => {
      //unmount
      dispatch(cleanFieldAutocompleteSearch(name))
    }
  }, [])

  //atualização de form
  useEffect(() => {
    //verificar se form data mudou
    if (formHasSubmitted(formStore, name)) {
      //envia para saga de criação de pessoas (cria usuario se for novo)
      submitForm(dispatch, formStore, name, upsertCondominium)
    }
    //se o upsert mudar o formulario pra persister quer dizer que inseriu o dado ok
    if (formHasPersisted(formStore, name)) {
      //limpa estado do form - tentar colocar no unmount
      cleanForm(dispatch, name)
      history.push(backToRoute)
    }
    // autocomplete condominium
    if (getAutocompleteFieldValue(formStore, name, "manager")) {
      //atualiza os campos do autocomplete de condominiumId
      updatedAutocompleteOptions(dispatch, formStore, name, "manager", fetchAutocompletePeople)
    }

    if (getAutocompleteFieldValue(formStore, name, "peoplesInCondominium")) {
      //atualiza os campos do autocomplete de condominiumId
      updatedAutocompleteOptions(dispatch, formStore, name, "peoplesInCondominium", fetchAutocompletePeople)
    }
  }, getFormUseEffectArray(formStore, name, [getAutocompleteFieldValue(formStore, name, "manager"), getAutocompleteFieldValue(formStore, name, "peoplesInCondominium")]))

  return (
    <Page title={title} backToRoute={backToRoute}>
      <FormContainer formTitle="Cadastro de Condomínio" name={name} />
    </Page>
  )
};

export default CondominiumForm;
