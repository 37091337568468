import { loginFlow } from "./login";
import { upsertPeopleFlow, getPeopleFlow, ACTION_UPSERT_PEOPLE, ACTION_GET_PEOPLE,  } from "./people";
import { all, fork, takeEvery } from 'redux-saga/effects';
import { ACTION_UPSERT_AUTHORIZED_PEOPLE, ACTION_GET_AUTHORIZED_PEOPLE, getAuthorizedPeopleFlow, upsertAuthorizedPeopleFlow } from "./authorized_people";
import { ACTION_GET_SCHEDULE, ACTION_UPSERT_SCHEDULE, getSchedulesFlow, upsertScheduleFlow } from "./schedule";
import { ACTION_GET_CONDOMINIUM, ACTION_UPSERT_CONDOMINIUM, getCondominiumFlow, upsertCondominiumFlow } from "./condominium";
import { ACTION_GET_DELIVERY, ACTION_UPSERT_DELIVERY, getDeliveryFlow, upsertDeliveryFlow } from "./delivery";
import { ACTION_GET_DOOR, ACTION_UPSERT_DOOR, getDoorsFlow, upsertDoorFlow } from "./door";
import { ACTION_GET_CONDOMINIUM_GATEKEEPER, ACTION_UPSERT_CONDOMINIUM_GATEKEEPER, getCondominiumGateKeeperFlow, upsertCondominiumGateKeeperFlow } from "./condominium_gatekeeper";

export default function* rootSaga() {
    yield takeEvery(ACTION_GET_PEOPLE, getPeopleFlow);
    yield takeEvery(ACTION_UPSERT_PEOPLE, upsertPeopleFlow);
    yield takeEvery(ACTION_GET_AUTHORIZED_PEOPLE, getAuthorizedPeopleFlow);
    yield takeEvery(ACTION_UPSERT_AUTHORIZED_PEOPLE, upsertAuthorizedPeopleFlow)
    yield takeEvery(ACTION_UPSERT_SCHEDULE, upsertScheduleFlow)
    yield takeEvery(ACTION_GET_SCHEDULE, getSchedulesFlow)
    yield takeEvery(ACTION_UPSERT_CONDOMINIUM, upsertCondominiumFlow)
    yield takeEvery(ACTION_GET_CONDOMINIUM, getCondominiumFlow)
    yield takeEvery(ACTION_UPSERT_DELIVERY, upsertDeliveryFlow)
    yield takeEvery(ACTION_GET_DELIVERY, getDeliveryFlow)
    yield takeEvery(ACTION_UPSERT_DOOR, upsertDoorFlow)
    yield takeEvery(ACTION_GET_DOOR, getDoorsFlow)
    yield takeEvery(ACTION_GET_CONDOMINIUM_GATEKEEPER, getCondominiumGateKeeperFlow)
    yield takeEvery(ACTION_UPSERT_CONDOMINIUM_GATEKEEPER, upsertCondominiumGateKeeperFlow)

    yield all([ fork(loginFlow) ])
}