import * as CONSTS from "../const"
import { ApiService } from "./api"

export class AuthorizedPersonApiService extends ApiService {
    async createAuthorizedPeople(authorizedPeople: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_AUTHORIZED}`
            const headers = { ...this.httpService.getHeader() }
            const authorizedPeopleResponse: any = await this.httpService.post(url, authorizedPeople, headers)

            if (!authorizedPeopleResponse.data) {
                throw new Error("Error ao criar pessoa autorizada")
            }

            return authorizedPeopleResponse.data
        } catch (error) {
            return error
        }
    }

    async updateAuthorizedPeople(authorizedPeople: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_AUTHORIZED}/${authorizedPeople.id}`
            const headers = { ...this.httpService.getHeader() }
            const authorizedPeopleResponse: any = await this.httpService.put(url, authorizedPeople, headers)

            if (authorizedPeopleResponse.data) {
                throw new Error("Error ao atualizar pessoa autorizada")
            }

            return authorizedPeopleResponse.data
        } catch (error) {
            return error
        }
    }

    async getAuthorizedPeople(id: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_AUTHORIZED}/${id}`
            const params: any = { filter: { include: [{ relation: 'condominium' }, { relation: 'people' }, { relation: 'parentPeople' }] } }
            const headers = { ...this.httpService.getHeader(), params }
            const authorizedPeopleGetResponse: any = await this.httpService.get(url, headers)

            if (!authorizedPeopleGetResponse.data) {
                throw new Error("Error ao carregar people")
            }

            return authorizedPeopleGetResponse.data
        } catch (error) {
            return error
        }
    }

    async getAuthorizedPeopleCount(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_AUTHORIZED_COUNT}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async getAuthorizedPeoples(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_AUTHORIZED}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async deleteAuthorizedPeople(id: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_AUTHORIZED}/${id}`
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.delete(url, headers)

        return response
    }
}