import _ from 'lodash';
import { call, put } from 'redux-saga/effects'
import { updateFormField, updateFormFieldValue, updateFormState } from '../actions/form'
import { addNotication } from '../components/Notification/Notification';
import { SECRET_SIP } from '../services/const';
import { ContainerService } from "../services/container";
import { UtilsService } from '../services/utils';

export const ACTION_UPSERT_CONDOMINIUM_GATEKEEPER = 'ACTION_UPSERT_CONDOMINIUM_GATEKEEPER'
export const ACTION_GET_CONDOMINIUM_GATEKEEPER = 'ACTION_GET_CONDOMINIUM_GATEKEEPER'
export const ACTION_ERROR_UPSERT_CONDOMINIUM_GATEKEEPER = 'ACTION_ERROR_UPSERT_CONDOMINIUM_GATEKEEPER'
export const ACTION_ERROR_CONDOMINIUM_GATEKEEPER = 'ACTION_ERROR_CONDOMINIUM_GATEKEEPER'

const containerService = new ContainerService().build()

const FORM_NAME = "condominium_gatekeeper"

export function* upsertCondominiumGateKeeperFlow(action: any) {
    try {
        if (UtilsService.formDataNotEmpty(action.formData)) {
            const { condominium_gatekeeper } = action.formData //nao esta vindo o user id
            const condominiumGateKeeperPayload = {
                id: condominium_gatekeeper.id,
                condominiumId: condominium_gatekeeper.condominiumId.id,
                extension: condominium_gatekeeper.extension,
                name: condominium_gatekeeper.name,
                condominiumDoorId: condominium_gatekeeper.door.id
            }
            if (!condominiumGateKeeperPayload.id) {
                yield call(
                    [
                        containerService.condominiumGateKeeperApiService,
                        containerService.condominiumGateKeeperApiService.createCondominiumGateKeeper
                    ],
                    _.omit(condominiumGateKeeperPayload, "id")
                )
            } else {
                yield call(
                    [
                        containerService.condominiumGateKeeperApiService,
                        containerService.condominiumGateKeeperApiService.updateCondominiumGateKeeper
                    ],
                    condominiumGateKeeperPayload
                )
            }

            const notification = {
                message: "Interfone salvo com sucesso",
                typeNotification: "success"
            }

            yield put(addNotication(notification))
        }
        // redirect quando edita ou salva
    } catch (error) {
        const notification = {
            message: error,
            typeNotification: "error"
        }

        yield put(addNotication(notification))

        //LIMPAR FORM
        yield put(updateFormState(FORM_NAME, { isPersisted: false, isSubmitted: false }))
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}

export function* getCondominiumGateKeeperFlow(action: any): any {
    try {
        const id: number = action.id
        if (id === 0) return;
        yield put({ type: 'PERSISTING_API' })

        const condominiumGateKeeper = yield call(
            [
                containerService.condominiumGateKeeperApiService,
                containerService.condominiumGateKeeperApiService.getCondominiumGateKeeper
            ],
            id
        )

        let files
        try {
            files = JSON.parse(condominiumGateKeeper.files)
        } catch (err) {
            files = []
        }

        condominiumGateKeeper.extension_sip = `condominium_${condominiumGateKeeper.condominium?.id}_gatekeeper_${condominiumGateKeeper.id}`
        condominiumGateKeeper.secret_sip = SECRET_SIP

        const condominiumGateKeeperResponse = {
            ...condominiumGateKeeper,
            condominiumId: condominiumGateKeeper.condominium ? {
                id: condominiumGateKeeper.condominium?.id,
                label: `${condominiumGateKeeper.condominium?.id} - ${condominiumGateKeeper.condominium?.name}`
            } : null,
            door: condominiumGateKeeper.condominiumDoor ? {
                id: condominiumGateKeeper.condominiumDoor?.id,
                label: `${condominiumGateKeeper.condominiumDoor?.id} - ${condominiumGateKeeper.condominiumDoor?.label}`
            } : null,
        }

        yield put(updateFormFieldValue(FORM_NAME, condominiumGateKeeperResponse, action.fields))

    } catch (error) {
        console.log('getCondominiumGateKeeperFlow', error)
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}