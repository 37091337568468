export const LAST_LOGIN_EMAIL_LOCALSTORAGE_ITEM = 'lastEmail'
export const AUTHORIZED_LOCALSTORAGE_ITEM = 'authorized'
export const AUTHORIZED_USER_LOCALSTORAGE_TOKEN = 'userToken'
export const AUTHORIZED_USER_LOCALSTORAGE_ITEM = 'userData'
export const API_VALID_USER_MESSAGE = 'Usuario encontrado.'
export const API_INVALID_USER_MESSAGE = 'Usuario não encontrado.'
export const API_URL = process.env.REACT_APP_URL
export const SECRET_SIP = process.env.REACT_APP_SECRET_SIP
export const API_URL_LOGIN = "/users/login"
export const API_URL_WHOIAM = "/users/whoAmI"
export const API_URL_PEOPLE = "/people"
export const API_URL_PEOPLE_BY_CONDOMINIUM = "/people/condominium"
export const API_URL_CONDOMINIUM_WHEREILIVE = "/condominium-where-i-live"
export const API_URL_PEOPLES_IN_CONDOMINIUM = "/peoples-in-condominium"
export const API_URL_CONDOMINIUM = "/condominium"
export const API_URL_CONDOMINIUM_COUNT = "/condominium/count"
export const API_URL_PEOPLE_COUNT = "/people/count"
export const API_URL_VERIFY_EMAIL = "/user/verify-email"
export const API_URL_USER_SIGNIN = '/signup'
export const API_URL_USER_CHANGE_PASSWORD = '/reset-password'
export const API_URL_USER_ADMIN = '/user/grant-admin'
export const API_URL_AUTHORIZED = '/authorized-people'
export const API_URL_AUTHORIZED_COUNT = '/authorized-people/count'
export const API_URL_DELIVERY = '/delivery'
export const API_URL_DELIVERY_COUNT = '/delivery/count'
export const API_URL_SCHEDULE = '/schedule'
export const API_URL_SCHEDULE_COUNT = '/schedule/count'
export const API_URL_DOOR = '/condominium-doors'
export const API_URL_DOOR_COUNT = '/condominium-doors/count'
export const API_URL_DOOR_PEOPLE = '/condominium-doors-people'
export const API_URL_FILES = '/files'
export const API_URL_FILES_DOWNLOAD = '/files/download'
export const API_URL_FILES_UPLOAD = '/files/upload'
export const API_URL_FILES_DELETE = '/files/delete'
export const API_URL_CONDOMINIUM_GATEKEEPER = '/condominium-gatekeeper'
export const API_URL_CONDOMINIUM_GATEKEEPER_COUNT = '/condominium-gatekeeper/count'
export const API_URL_CONDOMINIUM_MESSAGE = '/condominium-message'
export const API_URL_CONDOMINIUM_MESSAGE_COUNT = '/condominium-message/count'
export const API_URL_SCREENVIEWS = '/screenviews'
export const API_URL_PABX = '/phone/generate-peers'
export const API_URL_PABX_DELETE = '/phone/delete-peers'
export const API_URL_PABX_TRUNK = '/phone/generate-trunk'
export const API_URL_PEOPLE_GROUP = '/phone/phone-group'