import _ from 'lodash';
import { call, put } from 'redux-saga/effects'
import { updateFormField, updateFormFieldValue, updateFormState } from '../actions/form'
import { addNotication } from '../components/Notification/Notification';
import { ContainerService } from "../services/container";
import { UtilsService } from '../services/utils';

export const ACTION_UPSERT_DELIVERY = 'ACTION_UPSERT_DELIVERY'
export const ACTION_GET_DELIVERY = 'ACTION_GET_DELIVERY'
export const ACTION_ERROR_UPSERT_DELIVERY = 'ACTION_ERROR_UPSERT_DELIVERY'
export const ACTION_ERROR_DELIVERY = 'ACTION_ERROR_DELIVERY'

const containerService = new ContainerService().build()

const FORM_NAME = "delivery"

export function* upsertDeliveryFlow(action: any) {
    try {
        if (UtilsService.formDataNotEmpty(action.formData)) {
            const { delivery } = action.formData //nao esta vindo o user id
            const deliveryPayload = {
                id: delivery.id,
                peopleId: delivery.peopleId.id,
                condominiumId: delivery.condominiumId.id,
                name: delivery.name,
                type: delivery.type,
                files: JSON.stringify(delivery?.files) || JSON.stringify([]),
                identification: delivery?.identification || ""
            }
            if (!deliveryPayload.id) {
                yield call(
                    [
                        containerService.deliveryApiService,
                        containerService.deliveryApiService.createDelivery
                    ],
                    _.omit(deliveryPayload, "id")
                )
            } else {
                yield call(
                    [
                        containerService.deliveryApiService,
                        containerService.deliveryApiService.updateDelivery
                    ],
                    deliveryPayload
                )
            }

            const notification = {
                message: "Entrega salvo com sucesso",
                typeNotification: "success"
            }

            yield put(addNotication(notification))
        }
        // redirect quando edita ou salva
    } catch (error) {
        const notification = {
            message: error,
            typeNotification: "error"
        }

        yield put(addNotication(notification))

        //LIMPAR FORM
        yield put(updateFormState(FORM_NAME, { isPersisted: false, isSubmitted: false }))
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}

export function* getDeliveryFlow(action: any): any {
    try {
        const id: number = action.id
        if (id === 0) return;
        yield put({ type: 'PERSISTING_API' })

        const delivery = yield call(
            [
                containerService.deliveryApiService,
                containerService.deliveryApiService.getDelivery
            ],
            id
        )

        let files
        try {
            files = JSON.parse(delivery.files)
        } catch (err) {
            files = []
        }

        const deliveryResponse = {
            ...delivery,
            files,
            peopleId: delivery.people ? {
                id: delivery.people?.id,
                label: `${delivery.people?.id} - ${delivery.people?.name}`
            } : null,
            condominiumId: delivery.condominium ? {
                id: delivery.condominium?.id,
                label: `${delivery.condominium?.id} - ${delivery.condominium?.name}`
            } : null,
        }

        yield put(updateFormFieldValue(FORM_NAME, deliveryResponse, action.fields))

    } catch (error) {
        console.log('getDeliveryFlow', error)
    } finally {
        yield put({ type: 'STAND_BY' })
    }
}