import React from 'react'
import { Redirect, Route } from "react-router"
import RoutesConsts from "../../consts/RoutesConsts"
import { ContainerService } from "../../services/container"

const containerService = new ContainerService().build()

export const PrivateRoute = ({ component: Component, ...rest }: { component: any, path?: string, exact?: boolean }) => {
    const redirectDefault = RoutesConsts.LOGIN
    return (
        <Route {...rest} render={props => (
            containerService.authService.isAlreadyLogged() ?
                <Component {...props} />
                : <Redirect to={redirectDefault} />
        )} />
    );
};
