import * as CONSTS from "../const"
import { ApiService } from "./api"

export class DoorApiService extends ApiService {

    async createDoor(door: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_DOOR}`
            const headers = { ...this.httpService.getHeader() }
            const doorResponse: any = await this.httpService.post(url, door, headers)

            if (!doorResponse.data) {
                throw new Error("Error ao criar porta")
            }

            return doorResponse.data
        } catch (error) {
            return error
        }
    }

    async updateDoor(door: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_DOOR}/${door.id}`
            const headers = { ...this.httpService.getHeader() }
            const doorResponse: any = await this.httpService.put(url, door, headers)

            if (!doorResponse.data) {
                throw new Error("Error ao criar porta")
            }

            return doorResponse.data
        } catch (error) {
            return error
        }
    }

    async getDoorsCount(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_DOOR_COUNT}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async getDoors(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_DOOR}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async deleteDoors(id: number) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_DOOR}/${id}`
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.delete(url, headers)

        return response
    }

    async getDoor(id: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_DOOR}/${id}`
            const params: any = { filter: { include: [{ relation: 'condominium' }] } }
            const headers = { ...this.httpService.getHeader(), params }
            const doorResponse: any = await this.httpService.get(url, headers)

            if (!doorResponse.data) {
                throw new Error("Error ao carregar porta")
            }

            return doorResponse.data
        } catch (error) {
            return error
        }
    }

    async getPeoplesPrivateByDoorId(id: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_DOOR_PEOPLE}/door/${id}`
            const params: any = {}
            const headers = { ...this.httpService.getHeader(), params }
            const doorResponse: any = await this.httpService.get(url, headers)

            if (!doorResponse.data) {
                throw new Error("Error ao carregar porta")
            }

            return doorResponse.data
        } catch (error) {
            return error
        }
    }


    async deleteAllPeoplesFromPrivateDoor(id: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_DOOR_PEOPLE}/door/${id}`
            const params: any = {}
            const headers = { ...this.httpService.getHeader(), params }

            await this.httpService.delete(url, headers)
        } catch (error) {
            return error
        }
    }
    
    async createPeoplesFromPrivateDoor(id:string , peoplesIds: any) {
        const payload = {
            condominiumDoorId: id,
            peoplesIds
        }

        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_DOOR_PEOPLE}/door`
            const headers = { ...this.httpService.getHeader() }

            await this.httpService.post(url, payload, headers)
        } catch (error) {
            return error
        }
    }
}
