import React, { useEffect, useState } from 'react';
import { IonButton, IonIcon, IonGrid, IonCol, IonCard, IonRow, IonItem, IonCardHeader, IonCardTitle, IonCardContent } from "@ionic/react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { saveOutline } from "ionicons/icons";
import * as Yup from "yup";
import { Grid, FormControl } from '@material-ui/core';
import _ from 'lodash';
import Field, { TYPE_ID } from './Field';
import { persistApiLoading } from '../../actions'
import { updateFormField } from '../../actions/form'
import Loader from '../Loader/Loader';

const FormContainer: React.FC<any> = (params) => {
    const dispatch = useDispatch()
    const formStore = useSelector((state: any) => state.formState[params.name])
    const [notRendered, setNotRendered] = useState(false)

    //manipulação de campos do form
    const isFieldsEmpty = (fields: any) => Object.keys(fields).length === 0 ? true : false
    const getFieldsName = (fields: any) => Object.keys(fields)

    const getDefaultFieldsValues = (fields: any) => {
        return getFieldsName(fields).reduce((accumulator, fieldName) => {
            return { ...accumulator, [fieldName]: fields[fieldName].value }
        }, {})
    }

    const getFieldsValidation = (fields: any) => {
        if (isFieldsEmpty(fields)) {
            return null
        }

        const validationObject = getFieldsName(fields).reduce((accumulator, fieldName) => {
            if (fields[fieldName].validation) {
                return { ...accumulator, [fieldName]: fields[fieldName].validation }
            }
            return accumulator
        }, {})

        return Yup.object().shape(validationObject)
    }

    //criar metodo para render por chamada no array contar array e criar array de acordo a json de form
    const renderFields = (fields: any, readOnly: boolean, formik: any, formaName: string) => {
        // const usedFields:any = []
        // desenvolver group by materials-ui
        //criar fieldset par grupos ex people / usuarios
        const render = (chunkObject: any) => (
            chunkObject.map((fieldSet: any, indexFieldSet: number) => (
                fieldSet.map((field: any, indexField: number) => (
                    <div key={`${indexField}`} style={{ padding: field.type === TYPE_ID ? '0px' : '10px 0px' }}>
                        <Field
                            name={field.name}
                            field={field}
                            readOnly={readOnly}
                            formik={formik}
                            formName={params.name}
                        />
                    </div>
                ))
            ))
        );

        const inputsPerRow = 1
        //estrategia de render 1
        const chunckRender = (fields: any, inputsPerRow: number) => {
            const chunkObject: any = _.chunk(_.toArray(fields), inputsPerRow)
            return (<> {render(chunkObject)} </>)
        }
        //estrategia de render 2
        const chunckByRow = (fields: any[]) => {
            //todos elementos tem q ter
            //regra errada
            const allFieldsWithPropRow = _.toArray(fields).reduce((acc, f) => {
                if (f.row)
                    return [...acc, 1]
                return acc
            }, []).length !== _.toArray(fields).length;

            if (allFieldsWithPropRow) {
                throw new Error("Todos os objetos do arrays precisam ter o parametro row")
            }
            const chunkObject = _.toArray(_.chain(_.toArray(fields)).groupBy("row").value())
            return (<> {render(chunkObject)} </>)
        }

        return params.chunckRender ? chunckRender(fields, inputsPerRow) : chunckByRow(fields)
    }

    const putFormDataInTheirGroups = (formData: any) => {
        return _.reduce(formData, (accumulatorFormData: any, formDataField: any, formDataFieldIndex: any) => {
            const field = formStore.fields[formDataFieldIndex]

            if (!accumulatorFormData[field.group]) {
                accumulatorFormData[field.group] = {}
            }
            accumulatorFormData[field.group][formDataFieldIndex] = formDataField
            return accumulatorFormData
        }, {})
    }

    useEffect(() => {
        setTimeout(() => {
            setNotRendered(true)
        })
    })

    if (!formStore || !formStore.fields) return null
    return (
        <>
            {notRendered ?
                <Formik
                    enableReinitialize
                    initialValues={getDefaultFieldsValues(formStore.fields)}
                    validationSchema={getFieldsValidation(formStore.fields)}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validateOnMount={false}
                    onSubmit={(values, formik) => {
                        formik.setSubmitting(true)
                        const formDataGroups = putFormDataInTheirGroups(values)
                        //envia para o useEffect da pagina do form
                        dispatch(updateFormField(params.name, formDataGroups, { isSubmitted: true }))
                        //inicia loading
                        dispatch(persistApiLoading())
                        formik.setSubmitting(false)
                    }} >
                    {formik => {
                        return (<form onSubmit={formik.handleSubmit}>
                            <IonGrid fixed={true} className="ion-flex ion-flex-column ion-justify-content-center" style={{ height: '100%' }}>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>{params.formTitle}</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol>
                                                            {renderFields(formStore.fields, formStore.isReadOnly, formik, params.name)}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="ion-flex ion-justify-content-end">
                                                <IonButton color="dark" type="submit" disabled={formStore.isSubmitted || formStore.isReadOnly}>
                                                    <IonIcon icon={saveOutline} /> Salvar
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonGrid>
                        </form>)
                    }}
                </Formik> : <Loader />}
        </>)

};

export default FormContainer;