import React from 'react';
import './Delivery.css';
import { FormatDate } from "../../components/DataTable/DataTableContainer";
import RoutesConsts from "../../consts/RoutesConsts";
import { ContainerService } from '../../services/container';
import ApiDataTable from '../../components/ApiDataTable/ApiDataTable'
import Page from '../../components/Page/Page';

const containerService = new ContainerService().build()

const Delivery: React.FC = () => {

  const columns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Destinatario',
      sortable: true,
      cell: (row: any) => row?.people?.name,
    },
    {
      name: 'Identificação',
      sortable: true,
      cell: (row: any) => row?.identification || "Sem identificação",
    },
    {
      name: 'Pacote',
      selector: 'name',
      sortable: true,
      cell: (row: any) => row?.name || "Sem identificação",
    },
    {
      name: 'Tipo',
      sortable: true,
      cell: (row: any) => row?.type || "Sem tipo",
    },
    {
      name: 'Criado em',
      selector: 'createdAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.createdAt),
    },
    {
      name: 'Atualizado em',
      selector: 'updatedAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.updatedAt),
    }
  ];
  const include: any = [{ relation: 'people' }]
  return (
    <Page
      title={'Entregas'}
      backToRoute={RoutesConsts.MAIN}
    >
      <ApiDataTable
        include={include}
        routeCreate={RoutesConsts.DELIVERY_FORM}
        countService={(params: any) => containerService.deliveryApiService.getDeliveryCount(params)}
        getService={(filter: any) => containerService.deliveryApiService.getDeliveries(filter)}
        deleteRow={(filter: any) => containerService.deliveryApiService.deleteDelivery(filter)}
        columns={columns} />
    </Page>
  );
};

export default Delivery;