import React from 'react';
import { IonCard, IonGrid, IonCol } from '@ionic/react';
import './Door.css';
import { FormatDate } from "../../components/DataTable/DataTableContainer";
import RoutesConsts from "../../consts/RoutesConsts";
import ApiDataTable from '../../components/ApiDataTable/ApiDataTable'
import Page from '../../components/Page/Page';
import { ContainerService } from '../../services/container';

const Door: React.FC = () => {

  const columns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Condominio',
      sortable: true,
      selector: 'condominium.name',
      cell: (row: any) => row?.condominium?.name,
    },
    {
      name: 'Item',
      selector: 'item',
      sortable: true,
    },
    {
      name: 'Label',
      selector: 'label',
      sortable: true,
    },
    {
      name: 'Criado em',
      selector: 'createdAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.createdAt),
    },
    {
      name: 'Atualizado em',
      selector: 'updatedAt',
      sortable: true,
      cell: (row: any) => FormatDate(row.updatedAt),
    }
  ];
  const include: any = [{ relation: 'condominium' }]
  const containerService = new ContainerService().build()
  return (
    <Page title={'Portas'} backToRoute={RoutesConsts.MAIN}>
      <IonGrid fixed={true} className="ion-flex ion-flex-column ion-justify-content-center" style={{ height: '100%' }}>
        <IonCol>
          <IonCard>
            <ApiDataTable
              textNew='Nova Porta'
              include={include}
              routeCreate={RoutesConsts.CONDOMINIUM_DOOR_FORM}
              countService={(params: any) => containerService.doorApiService.getDoorsCount(params)}
              getService={(filter: any) => containerService.doorApiService.getDoors(filter)}
              deleteRow={(filter: any) => containerService.doorApiService.deleteDoors(filter)}
              columns={columns} />
          </IonCard>
        </IonCol>
      </IonGrid>
    </Page>
  );
};

export default Door;