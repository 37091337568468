export const PERSISTING_API = 'PERSISTING_API'
export const STAND_BY = 'STAND_BY'

const initialState: any = {
    loading: false
};

export const LoadingReducer = {
    loadingState: (state = initialState, action: any) => {
        switch (action.type) {
            case STAND_BY:
                return { ...state, loading: false }
            case PERSISTING_API:
                return { ...state, loading: true }
            default:
                return state
        }
    }
}