import { combineReducers } from 'redux';
import {UserReducer} from "./user";
import {FormReducer} from "./form";
import {LoginReducer} from "./login"
import {NotificationReducer} from "./notification"
import {LoadingReducer} from "./loading"

const Reducers = combineReducers({...UserReducer, 
                                ...FormReducer, 
                                ...LoginReducer,
                                ...NotificationReducer,
                                ...LoadingReducer})
export default Reducers