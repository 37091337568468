export const ADD_NOTIFICATION_MESSAGE = 'ADD_NOTIFICATION_MESSAGE'
export const UPDATE_NOTIFICATION_MESSAGE = 'UPDATE_NOTIFICATION_MESSAGE'

const initialState = {
    messages:[]
}

export const NotificationReducer = {
    notificationState: (state: any = initialState, action: any) => {
        const { type, ...newAction } = action;
        switch (action.type) {
            
            case ADD_NOTIFICATION_MESSAGE:
                console.log(action)
                return {
                    ...state,
                    messages:[...state.messages, newAction]
                }

            case UPDATE_NOTIFICATION_MESSAGE:
                const newMessageArray = [...state.messages]
                newMessageArray[action.index] = newAction
                return {
                    ...state,
                    messages: newMessageArray
                }
    
            default:
                return state;
        }
    }
}