import { IonButton, IonModal } from '@ionic/react';
import { Button} from '@material-ui/core';
import React, { useState } from 'react';
import './ModalContainer.css';

const ModalContainer: React.FC<any> = (params) => {
  const [showModal, setShowModal] = useState(false as boolean);
  return (
      <>
        <Button variant="contained" 
                color="primary"  
                style={params.style}
                onClick={e=>{ 
                  setShowModal(true)
                }}>
          {params.buttonLabel || 'Abrir'}
        </Button>
        <IonModal 
        swipeToClose={true}
        onDidDismiss={() => setShowModal(false)} 
        isOpen={showModal} cssClass='my-custom-class'>
          {params.children}
          <IonButton onClick={() => setShowModal(false)}>Fechar</IonButton>
        </IonModal>
      </>
  );
};

export default ModalContainer;