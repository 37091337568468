import {PERSISTING_API, STAND_BY} from "../reducers/loading";
import { UPDATE_USER } from "../reducers/user";
import { ACTION_GET_PEOPLE, ACTION_UPSERT_PEOPLE } from "../saga/people";
import {ACTION_GET_AUTHORIZED_PEOPLE, ACTION_UPSERT_AUTHORIZED_PEOPLE} from "../saga/authorized_people";
import { ACTION_GET_SCHEDULE, ACTION_UPSERT_SCHEDULE } from "../saga/schedule";
import { ACTION_GET_CONDOMINIUM, ACTION_UPSERT_CONDOMINIUM } from "../saga/condominium";
import { ACTION_GET_DELIVERY, ACTION_UPSERT_DELIVERY } from "../saga/delivery";
import { ACTION_GET_DOOR, ACTION_UPSERT_DOOR } from "../saga/door";
import { ACTION_GET_CONDOMINIUM_GATEKEEPER, ACTION_UPSERT_CONDOMINIUM_GATEKEEPER } from "../saga/condominium_gatekeeper";

export const updateUser = (user:any) => ({
    type: UPDATE_USER,
    user
})

export const persistApiLoading = () => ({type: PERSISTING_API})
export const standByLoading = () => ({type: STAND_BY})

export const upsertAuthorizedPeople = (formData:any, fields:any) => ({type: ACTION_UPSERT_AUTHORIZED_PEOPLE, formData, fields})
export const getAuthorizedPeople = (id:any, fields:any) => ({type: ACTION_GET_AUTHORIZED_PEOPLE, id, fields})

export const upsertPeople = (formData:any, fields:any) => ({type: ACTION_UPSERT_PEOPLE, formData, fields})
export const getPeople = (id:any, fields:any) => ({type: ACTION_GET_PEOPLE, id, fields})

export const upsertSchedule = (formData:any, fields:any) => ({type: ACTION_UPSERT_SCHEDULE, formData, fields})
export const getSchedule = (id:any, fields:any) => ({type: ACTION_GET_SCHEDULE, id, fields})

export const upsertCondominium = (formData:any, fields:any) => ({type: ACTION_UPSERT_CONDOMINIUM, formData, fields})
export const getCondominium = (id:any, fields:any) => ({type: ACTION_GET_CONDOMINIUM, id, fields})

export const upsertDelivery = (formData:any, fields:any) => ({type: ACTION_UPSERT_DELIVERY, formData, fields})
export const getDelivery = (id:any, fields:any) => ({type: ACTION_GET_DELIVERY, id, fields})

export const upsertDoor = (formData:any, fields:any) => ({type: ACTION_UPSERT_DOOR, formData, fields})
export const getDoor = (id:any, fields:any) => ({type: ACTION_GET_DOOR, id, fields})

export const upsertCondominiumGateKeeper = (formData:any, fields:any) => ({type: ACTION_UPSERT_CONDOMINIUM_GATEKEEPER, formData, fields})
export const getCondominiumGateKeeper = (id:any, fields:any) => ({type: ACTION_GET_CONDOMINIUM_GATEKEEPER, id, fields})
