import * as CONSTS from "../const"
import { ApiService } from "./api"

export class CondominiumApiService extends ApiService {
    async getCondominium(id: number) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM}/${id}`
        const params: any = { filter: { include: [{ relation: "manager" }] } }
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async createCondominium(condominium: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM}`
            const headers = { ...this.httpService.getHeader() }
            const condominiumResponse: any = await this.httpService.post(url, condominium, headers)

            if (!condominiumResponse.data) {
                throw new Error("Error ao criar condominio")
            }

            return condominiumResponse.data
        } catch (error) {
            return error
        }
    }

    async updateCondominium(condominium: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM}/${condominium.id}`
            const headers = { ...this.httpService.getHeader() }
            const condominiumResponse: any = await this.httpService.put(url, condominium, headers)

            if (!condominiumResponse.data) {
                throw new Error("Error ao atualizar condominio")
            }

            return condominiumResponse.data
        } catch (error) {
            return error
        }
    }

    async createPeoplesInCondominium(id: any, peoplesIds: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM}/${id}${CONSTS.API_URL_PEOPLES_IN_CONDOMINIUM}`
            const headers = { ...this.httpService.getHeader() }
            const responseCreatePeoplesInCondominium: any = await this.httpService.post(url, { peoplesIds }, headers)

            if (!responseCreatePeoplesInCondominium.data) {
                throw new Error("Erro ao sallvar condominium dos usuarios")
            }

            return responseCreatePeoplesInCondominium.data
        } catch (error) {
            return error
        }
    }

    async getPeoplesInCondominium(id: any, params: any) {
        try {
            const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM}/${id}${CONSTS.API_URL_PEOPLES_IN_CONDOMINIUM}`
            const headers = { ...this.httpService.getHeader(), params }
            const responsePeoplesInCondominium: any = await this.httpService.get(url, headers)

            if (!responsePeoplesInCondominium.data) {
                throw new Error('Error ao carregar people in condominium')
            }

            return responsePeoplesInCondominium.data
        } catch (error) {
            return error
        }
    }

    async getCamerasByCondominium(id: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM}/screenviews/${id}`
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async getCondominiumsCount(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM_COUNT}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async getCondominiums(params: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM}`
        const headers = { ...this.httpService.getHeader(), params }
        const response = await this.httpService.get(url, headers)

        return response
    }

    async deleteCondominium(id: any) {
        const url = `${CONSTS.API_URL}${CONSTS.API_URL_CONDOMINIUM}/${id}`
        const headers = { ...this.httpService.getHeader() }
        const response = await this.httpService.delete(url, headers)

        return response
    }
}