import React, {useEffect} from 'react';
import './PeopleForm.css';
import RoutesConsts from "../../consts/RoutesConsts";
import FormContainer from "../../components/FormContainer/FormContainer";
import {
  TYPE_AUTO_COMPLETE,
  TYPE_ID,
  TYPE_PASSWORD,    
  TYPE_SELECT,
  TYPE_TEXT,
} from "../../components/FormContainer/Field"
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import Page from '../../components/Page/Page';
//import _ from 'lodash';
import { validationEmailApi } from '../../components/FormContainer/Validations';
import { getPeople, upsertPeople } from '../../actions';
import {
  cleanFieldAutocompleteSearch,
  createFormField,
} from '../../actions/form';
import { useHistory } from 'react-router';
import { ContainerService } from '../../services/container';
import { cleanForm, formHasPersisted, formHasSubmitted, getAutocompleteFieldValue, getFormUseEffectArray, submitForm, updatedAutocompleteOptions } from '../../components/FormContainer/FormEffects';

const PeopleForm: React.FC = (params: any) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const formStore = useSelector((state: any) => state.formState)

  const id: number = parseInt(params.match.params.id)
  const name = 'people'
  const backToRoute = RoutesConsts.PEOPLE
  const title = 'Pessoas'

  const containerService = new ContainerService().build()

  const isEdit = () => {
    return id && id !== 0
  }

  //colocar em actions/index.tsx
  const loadForm = (id: number) => {
    const fields = {
      "id": {type:TYPE_ID, name: "id", row:1, group: "people", label: "Cod. Pessoa", offset:0, value:0, validation: null},
      "name": {type:TYPE_TEXT, name: "name", row:2, group: "people", label: "Nome", value:"", validation: Yup.string().required('Informe um nome')},
      "socialId": {type:TYPE_TEXT, name: "socialId", row:3, offset:1, group: "people", label: "Identidade", value:"", validation: null},
      "condominiumWhereILive": {type:TYPE_AUTO_COMPLETE, name: "condominiumWhereILive", row:4, offset:1, group: "people", options:[], async:true, multiple:true, label: "Condominios", value:[], validation: null/*Yup.array().required('Selecione algum condominio')*/},
      "extension": { type: TYPE_TEXT, name: "extension", row: 5, group: "people", label: "Interfone", value: "", validation: null },
      "phone": { type: TYPE_TEXT, name: "phone", row: 5, group: "people", label: "Telefone", value: "", validation: null },
      "userId": {type:TYPE_ID, name: "userId", row:5, group: "people", label: "Cod. Usuario", offset:1, value:0, validation: null},
      "email": {type:TYPE_TEXT, name: "email", row:6, group: "user", label:"Email", value:"", validation: !isEdit() ? validationEmailApi : null, readOnly: isEdit() ? true : false},
      "password":{type:TYPE_PASSWORD, name: "password", row:7, group: "user", label:"Senha", value:"", validation: !isEdit() ? Yup.string().required('Informe uma senha') : null},
      "roles":{type:TYPE_SELECT, name: "roles", row:8, offset:1, group: "user", label:"Administrador", value: false, options: [{label:"Sim", value:true}, {label:"Não", value:false}], validation: Yup.string().required()},
      
      "extension_sip": { type: TYPE_TEXT, name: "extension_sip", row: 9, readOnly: true, group: "sip", label: "Ramal", value: "", validation: null },
      "secret_sip": { type: TYPE_TEXT, name: "secret_sip", row: 9, readOnly: true, group: "sip", label: "Senha", value: "", validation: null },
    }

    //carrega form no redux - se nao colocar nao será renderizado pelo componente
    dispatch(createFormField(name, fields))

    if (isEdit()) {
      //altera campos de acordo com a edição
      console.log("Carregando dados do id", id)
      dispatch(getPeople(id, fields))
    }

  }

  const fetchAutocompleteCondominium = async (condominiumValue: any) => {
    const filter: any = {
      filter: {
        where: {
          name: { like: `${condominiumValue}%` },
          deletedAt: {
            eq: null
          }
        }
      }
    }

    const condominiums = await containerService.condominiumApiService.getCondominiums(filter)
    if (condominiums.data) {
      return condominiums.data.reduce((accCondominium: any, condominium: any) =>
        [...accCondominium, { id: condominium.id, label: condominium.name }], [])
    }
    return []
  }

  //init
  useEffect(() => {
    //Carrega dados do formulario para redux
    //para cada form é necessario um dispatch para criar os dados no redux
    loadForm(id)

    return () => {
      //unmount
      dispatch(cleanFieldAutocompleteSearch(name))
    }
  }, [])

  //atualização de form
  useEffect(() => {
    //verificar se form data mudou
    if (formHasSubmitted(formStore, name)) {
      //envia para saga de criação de pessoas (cria usuario se for novo)
      submitForm(dispatch, formStore, name, upsertPeople)
    }
    //se o upsert mudar o formulario pra persister quer dizer que inseriu o dado ok
    if (formHasPersisted(formStore, name)) {
      //limpa estado do form - tentar colocar no unmount
      cleanForm(dispatch, name)
      history.push(backToRoute)
    }
    // autocomplete condominium
    if (getAutocompleteFieldValue(formStore, name, "condominiumWhereILive")) {
      updatedAutocompleteOptions(dispatch, formStore, name, "condominiumWhereILive", fetchAutocompleteCondominium)
    }
  }, getFormUseEffectArray(formStore, name, [getAutocompleteFieldValue(formStore, name, "condominiumWhereILive")]))

  return (
    <Page title={title} backToRoute={backToRoute}>
      <FormContainer formTitle="Cadastro de Pessoa" name={name} />
    </Page>
  )
};

export default PeopleForm;
